import { Spinner } from '@cma/common'
import {
  CmaThumbnailIcon,
  DocumentThumbnailIcon,
  FlyerThumbnailIcon,
  PropertyThumbnailIcon,
  TourThumbnailIcon
} from '@cma/icons'
import pluralize from 'pluralize'
import { useReportStatus } from './useReportStatus'

interface ReportCardPreviewReport {
  __typename: string
  id: string
  status?: string | null
  addressParts?: string[] | null
  cities?: string[] | null
  listingCount?: number | null
  thumbnail?: string | null
  title?: string | null
  pdfPermalink?: string | null
  guid?: string | null
}

interface ReportCardPreviewProps {
  report: ReportCardPreviewReport
  showPdfReport: boolean
  showSlideshowReport: boolean
  showLiveReport: boolean
}

export function ReportCardPreview({
  report,
  showPdfReport,
  showSlideshowReport,
  showLiveReport
}: ReportCardPreviewProps) {
  const reportType = report.__typename || 'CmaReport'
  const isPublishing = report.status === 'active-report'
  const isPendingPublish = report.status === 'pending-report'
  const isPublished = report.status === 'published-report'
  const [street, cityStateZip] = report.addressParts ?? []
  const listingCount = pluralize('Properties', report.listingCount ?? 0, true)
  const cities = !report.cities?.length
    ? 'No cities Available'
    : report.cities.join(', ')

  useReportStatus({ id: report.id, status: report.status || 'pending-report' })

  const ThumbnailIcon = {
    CmaReport: CmaThumbnailIcon,
    TourReport: TourThumbnailIcon,
    PropertyReport: PropertyThumbnailIcon,
    FlyerReport: FlyerThumbnailIcon,
    DocumentReport: DocumentThumbnailIcon
  }[reportType]

  return (
    <div className="flex w-full max-w-[26.75rem] flex-col rounded-md bg-white shadow">
      <div className="relative flex flex-grow p-3">
        {report.thumbnail ? (
          <div
            style={{ backgroundImage: `url('${report.thumbnail}')` }}
            className="h-32 w-24 flex-shrink-0 rounded-sm bg-gray-200"
          />
        ) : ThumbnailIcon ? (
          <ThumbnailIcon className="h-32 w-24 flex-shrink-0" />
        ) : null}
        <div className="min-w-0 px-4 pt-2">
          <strong
            className="my-1 block truncate font-medium"
            title={report.title || ''}>
            {report.title}
          </strong>
          {report.__typename === 'TourReport' && (
            <>
              <div
                className="truncate text-xs text-gray-700"
                title={listingCount}>
                {listingCount}
              </div>
              {cities && (
                <div
                  className="mt-0.5 truncate text-xs font-normal text-gray-700"
                  title={cities}>
                  {cities}
                </div>
              )}
            </>
          )}
          {report.__typename !== 'TourReport' && (
            <>
              <div className="truncate text-sm text-gray-700" title={street}>
                {street}
              </div>
              {cityStateZip && (
                <div className="mt-0.5 flex items-center space-x-2 ">
                  <div
                    className="truncate text-sm font-normal text-gray-700"
                    title={cityStateZip}>
                    {cityStateZip}
                  </div>
                </div>
              )}
            </>
          )}
          {(showPdfReport || showSlideshowReport || showLiveReport) && (
            <div className="mt-3 flex space-x-3 text-sm text-blue-600">
              {isPublishing && (
                <button
                  disabled
                  className="flex cursor-not-allowed items-center space-x-1 font-medium text-gray-400">
                  <span className="h-4 w-4">
                    <Spinner />
                  </span>
                  <span>Publishing...</span>
                </button>
              )}
              {isPublished && report.pdfPermalink && (
                <>
                  {showPdfReport && (
                    <a
                      href={report.pdfPermalink}
                      target="_blank"
                      rel="noreferrer"
                      className="truncate font-medium">
                      View PDF
                    </a>
                  )}
                  {showSlideshowReport && (
                    <a
                      href={`${window.location.origin}/present/${report.guid}`}
                      target="_blank"
                      rel="noreferrer"
                      className="truncate font-medium">
                      View Slideshow
                    </a>
                  )}
                </>
              )}
              {isPendingPublish && (
                <>
                  {showPdfReport && (
                    <button
                      disabled
                      className="cursor-not-allowed truncate font-medium opacity-50">
                      View PDF
                    </button>
                  )}
                  {showSlideshowReport && (
                    <button
                      disabled
                      className="cursor-not-allowed truncate font-medium opacity-50">
                      View Slideshow
                    </button>
                  )}
                </>
              )}
              {showLiveReport && (
                <a
                  href={`${window.location.origin}/live/${report.guid}`}
                  target="_blank"
                  rel="noreferrer"
                  className="truncate font-medium">
                  View Live
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
