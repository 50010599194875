import { airbrake } from '@cma/common'
import {
  getAccessTokens,
  removeAccessTokens,
  setAccessTokens
} from '@cma/features/auth'
import { GraphQLClient, Variables } from 'graphql-request'

interface Api<V = Variables> {
  query: string
  variables?: V
  url?: string
  guid?: string
}

export async function api<T = any, V = Variables>({
  query,
  variables,
  url = process.env.VITE_APP_CMA_GRAPHQL_URL,
  guid
}: Api<V>): Promise<T> {
  try {
    const queryString = new URLSearchParams(window.location.search)
    const jwt = queryString.get('jwt')
    const impersonateJwt = queryString.get('impersonate')

    if (jwt || impersonateJwt) {
      setAccessTokens(jwt, impersonateJwt)

      const newUrl = new URL(window.location.href)
      newUrl.searchParams.delete('jwt')
      newUrl.searchParams.delete('impersonate')
      newUrl.searchParams.delete('t')

      window.history.pushState(null, '', newUrl.toString())
    }

    const accessTokens = getAccessTokens()
    const headers: Record<string, string> = {}

    if (guid) {
      headers['guid'] = guid
    } else if (accessTokens?.currentToken) {
      headers['Authorization'] = `Bearer ${accessTokens.currentToken}`
    }

    const graphqlClient = new GraphQLClient(url, {
      headers
    })
    const res: any = await graphqlClient.request<T, V>(query, variables)
    const resValues = Object.values(res)

    const errors = resValues.reduce((allErrors: any[], item: any) => {
      if (item?.errors) {
        return [...allErrors, ...item.errors]
      }
      return allErrors
    }, [])

    if (errors.length) {
      // @ts-ignore we can throw an array and it'll join for us
      throw new Error(errors)
    }

    return res
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // https://github.com/prisma-labs/graphql-request#error-handling
    const [firstError] = error?.response?.errors || []
    const message = firstError?.message || error.message || 'An error occurred'
    const code = firstError?.extensions?.code || 'UNKNOWN_ERROR'

    if (
      code === 'UNAUTHENTICATED' ||
      message === 'Signature verification failed'
    ) {
      removeAccessTokens()
      window.open(`${process.env.VITE_APP_CMA_URL}/login`, '_self')
    }

    const status: number = error?.response?.status || -1
    if (status >= 500) {
      const accessTokens = getAccessTokens()
      airbrake?.notify({ error, params: { info: error, accessTokens } })
      throw new Error(
        "There seems to be a problem on our end. We've been notified and are looking into it."
      )
    }
    throw new Error(message)
  }
}
