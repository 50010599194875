import { Alert, formatCurrency, formatDate } from '@cma/common'
import { BillingOrderPartsFragment } from '@cma/generated/graphql'
import { ExternalLinkIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { useBilling } from './useBilling'
import { useDestroyOrder } from './useDestroyOrder'

export function BillingOrders() {
  const { data: billing } = useBilling()

  return (
    <div className="space-y-8">
      {!!billing?.orders?.recurring?.length && (
        <div className="space-y-3" data-testid="recurring-orders">
          <p className="text-sm text-gray-500">
            Your <strong>recurring</strong> transactions are listed below.
          </p>
          {billing.orders.recurring.map((order) => (
            <Order key={order.id} order={order} />
          ))}
        </div>
      )}
      {!!billing?.orders?.oneTime?.length && (
        <div className="space-y-3" data-testid="one-time-orders">
          <p className="text-sm text-gray-500">
            Your <strong>one-time</strong> transactions are listed below.
          </p>
          {billing.orders.oneTime.map((order) => (
            <Order key={order.id} order={order} />
          ))}
        </div>
      )}
    </div>
  )
}

interface OrderProps {
  order: BillingOrderPartsFragment
}

function Order({ order }: OrderProps) {
  const [showCancel, setShowCancel] = useState(false)
  const { mutate: destroyOrder, isLoading, error } = useDestroyOrder()

  return (
    <div data-testid="order">
      <strong className="flex items-center justify-between space-x-2 text-sm text-gray-600">
        <span>{order.name}</span>
        {!!order.price && <span>{formatCurrency(order.price, '$0.00')}</span>}
      </strong>
      <div className="flex items-center space-x-2 text-sm text-gray-500">
        {order.chargeDate && (
          <div>
            {order.chargeDate
              ? formatDate(order.chargeDate, 'MMMM dd, yyyy')
              : 'No Date'}
          </div>
        )}
        {!order.isPaid && (
          <>
            <div>-</div>
            <button
              className="flex items-center text-blue-600"
              onClick={() => {
                if (order.billingUrl) {
                  window.open(order.billingUrl, '_blank')
                  return
                }
                setShowCancel(true)
              }}>
              {order.billingUrl ? (
                <span className="flex items-center space-x-1">
                  <span className="flex-shrink-0">See Billing</span>
                  <ExternalLinkIcon className="h-4 w-4 flex-shrink-0" />
                </span>
              ) : (
                'Cancel Order'
              )}
            </button>
          </>
        )}
      </div>
      <Alert variant="danger" isOpen={showCancel}>
        <Alert.Title>Cancel Order</Alert.Title>
        <Alert.Content>
          <div className="space-y-2">
            <div>
              Are you sure you want to cancel the order:{' '}
              <strong>{order.name}</strong>?
            </div>
            {error && (
              <div className="text-red-500" role="alert" aria-live="polite">
                {error.message}
              </div>
            )}
          </div>
        </Alert.Content>
        <Alert.Cancel onClick={() => setShowCancel(false)}>Cancel</Alert.Cancel>
        <Alert.Confirm
          loading={isLoading}
          onClick={() => destroyOrder({ id: order.id })}>
          Cancel Forever
        </Alert.Confirm>
      </Alert>
    </div>
  )
}
