import {
  classNames,
  formatDate,
  getDateWithoutTimezone,
  isSameDayMonthAndYear
} from '@cma/common'
import { ChevronRightIcon } from '@cma/icons'
import { addDays, isAfter, isSameDay, isSameMonth, isSameYear } from 'date-fns'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'

const variants = {
  enter: (direction: number) => ({
    translateX: `${direction * 100}%`,
    opacity: 0
  }),
  center: {
    translateX: '0%',
    opacity: 1
  },
  exit: (direction: number) => ({
    translateX: `${direction * -100}%`,
    opacity: 0
  })
}

interface TourDateSelectorProps {
  date?: Date
  onSelect: (date: Date) => void
}

export function TourDateSelector({ date, onSelect }: TourDateSelectorProps) {
  const dateWithoutTimezone = getDateWithoutTimezone(date)
  const [page, setPage] = useState(() => {
    if (!date) return 0
    let page = 0
    let tries = 0
    const MAX_TRIES = 100

    const startDate = isAfter(getDateWithoutTimezone(), dateWithoutTimezone)
      ? dateWithoutTimezone
      : getDateWithoutTimezone()

    let isSelectedDate = false
    do {
      isSelectedDate = [
        addDays(startDate, page * 3),
        addDays(startDate, page * 3 + 1),
        addDays(startDate, page * 3 + 2)
      ].some(
        (date_) =>
          isSameDay(date, date_) &&
          isSameMonth(date, date_) &&
          isSameYear(date, date_)
      )

      if (!isSelectedDate) {
        page += 1
        tries += 1
      }
    } while (!isSelectedDate && tries < MAX_TRIES)

    return page
  })

  const [prev, setPrev] = useState([null, page]) // [prev, current]
  const [startDate] = useState(() =>
    date
      ? isAfter(getDateWithoutTimezone(), dateWithoutTimezone)
        ? dateWithoutTimezone
        : getDateWithoutTimezone()
      : getDateWithoutTimezone()
  )
  const dates = [
    addDays(startDate, page * 3),
    addDays(startDate, page * 3 + 1),
    addDays(startDate, page * 3 + 2)
  ]

  if (prev[1] !== page) {
    setPrev([prev[1], page])
  }

  const prevPage = prev[0]
  const direction = prevPage === null || page > prevPage ? 1 : -1

  return (
    <div className="-mx-3 overflow-hidden px-3 sm:-mx-8 sm:px-8">
      <div className="relative flex px-5">
        <AnimatePresence initial={false} mode="popLayout" custom={direction}>
          <motion.div
            layout
            key={page}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            custom={direction}
            transition={{ translate: { ease: 'easeInOut' } }}
            className="flex w-full shrink-0 gap-1.5">
            {dates.map((_date) => {
              const _dateWithoutTimezone = getDateWithoutTimezone(_date)
              const isSelectedDate = date
                ? isSameDayMonthAndYear(
                    dateWithoutTimezone,
                    _dateWithoutTimezone
                  )
                : false

              return (
                <button
                  key={_dateWithoutTimezone.toISOString()}
                  className={classNames(
                    'group flex w-1/3 items-center justify-center rounded-md border p-2 text-center hover:bg-white',
                    {
                      'bg-gray-100': !isSelectedDate,
                      'border-gray-900 bg-white': isSelectedDate
                    }
                  )}
                  onClick={() => onSelect(_dateWithoutTimezone)}>
                  <div>
                    <div
                      className={classNames('text-xs', {
                        'text-gray-500 group-hover:text-gray-600':
                          !isSelectedDate,
                        'text-gray-800': isSelectedDate
                      })}>
                      {formatDate(_dateWithoutTimezone, 'EEEE')}
                    </div>
                    <div
                      className={classNames('text-2xl', {
                        'text-gray-600': !isSelectedDate,
                        'text-gray-800': isSelectedDate
                      })}>
                      {formatDate(_dateWithoutTimezone, 'dd')}
                    </div>
                    <div
                      className={classNames('text-xs', {
                        'text-gray-500 group-hover:text-gray-600':
                          !isSelectedDate,
                        'text-gray-800': isSelectedDate
                      })}>
                      {formatDate(_dateWithoutTimezone, 'MMM')}
                    </div>
                  </div>
                </button>
              )
            })}
          </motion.div>
        </AnimatePresence>
        {isAfter(dates[0], startDate) && (
          <button
            className="absolute top-1/2 left-0 flex h-9 w-9 -translate-y-1/2 items-center justify-center rounded-full bg-white text-blue-600 shadow-overlay"
            onClick={() => setPage((page) => page - 1)}>
            <ChevronRightIcon className="h-5 w-5 -translate-x-[0.0625rem] rotate-180" />
          </button>
        )}
        <button
          className="absolute top-1/2 right-0 flex h-9 w-9 -translate-y-1/2 items-center justify-center rounded-full bg-white text-blue-600 shadow-overlay"
          onClick={() => setPage((page) => page + 1)}>
          <ChevronRightIcon className="h-5 w-5 translate-x-[0.0625rem]" />
        </button>
      </div>
    </div>
  )
}
