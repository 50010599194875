import { WistiaModal } from '@cma/common'
import { VideoCameraIcon } from '@heroicons/react/outline'
import { useState } from 'react'

interface ReportGettingStartedProps
  extends Omit<JSX.IntrinsicElements['button'], 'className' | 'onClick'> {
  videoId: string
}

export function ReportGettingStarted({
  videoId,
  children,
  ...props
}: ReportGettingStartedProps) {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <button
        {...props}
        className="flex h-[9.875rem] items-center justify-center space-x-2 rounded bg-white py-10 shadow"
        onClick={() => setShowModal(true)}>
        <VideoCameraIcon className="h-6 w-6 text-blue-600" />
        <div className="text-blue-600">{children}</div>
      </button>
      <WistiaModal
        videoId={videoId}
        title={children}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  )
}
