import { Listbox } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import {
  Children,
  cloneElement,
  createContext,
  Dispatch,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { Checkbox } from './Checkbox'
import { Label } from './Label'
import { classNames } from './utils'

type Variant = 'primary' | 'danger'

const DropdownContext = createContext<{
  variant: Variant
  disabled: boolean
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  buttonRef: RefObject<HTMLButtonElement>
  multiple?: boolean
  value: string | string[]
}>({
  variant: 'primary',
  disabled: false,
  isOpen: false,
  setIsOpen() {},
  buttonRef: { current: null },
  multiple: false,
  value: ''
})

type DropdownProps =
  | {
      variant?: Variant
      disabled?: boolean
      multiple?: false
      value: string
      onChange: (value: string) => void
    }
  | {
      variant?: Variant
      disabled?: boolean
      multiple: true
      value: string[]
      onChange: (value: string[]) => void
    }

export function Dropdown({
  variant = 'primary',
  disabled = false,
  children,
  multiple,
  value,
  onChange
}: PropsWithChildren<DropdownProps>) {
  const [isOpen, setIsOpen] = useState(false)
  const childrenArray = Children.toArray(children)
  const label = childrenArray.find(
    (child) => (child as any).type.name === DropdownLabel.name
  )
  const button = childrenArray.find(
    (child) => (child as any).type.name === DropdownButton.name
  )
  const options = childrenArray.filter(
    (child) =>
      (child as any).type.name !== DropdownButton.name &&
      (child as any).type.any !== DropdownLabel.name
  )

  const handleSelect = (newValue: string) => {
    if (multiple) {
      // If we already have that value, remove it
      if (value.includes(newValue)) {
        onChange(value.filter((val) => val !== newValue))
      }
      // Else add it
      else {
        onChange([...value, newValue])
      }
    } else {
      // If not multiple, send back the value
      onChange(newValue)
    }
  }

  const buttonRef = useRef<HTMLButtonElement>(null)
  const listRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!multiple) return

    const handler = (e: MouseEvent) => {
      const target = e.target as Node

      if (!isOpen) return
      if (buttonRef.current?.contains(target)) return
      if (listRef.current?.contains(target)) return

      setIsOpen(false)
    }

    window.addEventListener('click', handler)

    return () => {
      window.removeEventListener('click', handler)
    }
  }, [isOpen, multiple])

  return (
    <DropdownContext.Provider
      value={{
        variant,
        disabled,
        isOpen,
        setIsOpen,
        buttonRef,
        multiple,
        value
      }}>
      <Listbox disabled={disabled} value={value} onChange={handleSelect}>
        <div className="space-y-1">
          {label}
          <div className="relative">
            {button}
            {(!multiple || isOpen) && (
              <Listbox.Options
                static={multiple}
                as="div"
                ref={listRef}
                className="absolute z-10 mt-2 max-h-96 min-w-full overflow-auto whitespace-nowrap rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {options}
              </Listbox.Options>
            )}
          </div>
        </div>
      </Listbox>
    </DropdownContext.Provider>
  )
}

Dropdown.Label = DropdownLabel
Dropdown.Button = DropdownButton
Dropdown.OptionGroup = DropdownOptionGroup
Dropdown.Option = DropdownOption

function DropdownLabel({ children }: PropsWithChildren<unknown>) {
  return <Listbox.Label as={Label}>{children}</Listbox.Label>
}

function DropdownButton({ children }: PropsWithChildren<unknown>) {
  const { variant, disabled, isOpen, setIsOpen, buttonRef, multiple } =
    useContext(DropdownContext)

  return (
    <div className="relative rounded-md bg-white">
      <ChevronDownIcon className="absolute right-1 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-500" />
      {!multiple && (
        <Listbox.Button
          type="button"
          disabled={disabled}
          className={classNames(
            'relative w-full appearance-none rounded-md border bg-transparent p-2 pr-6 text-left shadow-sm focus:outline-none sm:text-sm',
            {
              'border-gray-300': variant === 'primary',
              'focus:border-blue-600': variant === 'primary' && !disabled,
              'border-blue-600': variant === 'primary' && !disabled && isOpen,

              'border-red-300': variant === 'danger',
              'focus:border-red-500': variant === 'danger' && !disabled,
              'border-red-500': variant === 'danger' && !disabled && isOpen,

              'cursor-pointer': !disabled,
              'cursor-not-allowed bg-gray-50': disabled
            }
          )}>
          <span className="block truncate">{children}</span>
        </Listbox.Button>
      )}
      {multiple && (
        <button
          type="button"
          disabled={disabled}
          ref={buttonRef}
          className={classNames(
            'relative w-full appearance-none rounded-md border bg-transparent p-2 pr-6 text-left shadow-sm focus:outline-none sm:text-sm',
            {
              'border-gray-300': variant === 'primary',
              'focus:border-blue-600': variant === 'primary' && !disabled,
              'border-blue-600': variant === 'primary' && !disabled && isOpen,

              'border-red-300': variant === 'danger',
              'focus:border-red-500': variant === 'danger' && !disabled,
              'border-red-500': variant === 'danger' && !disabled && isOpen,

              'cursor-pointer': !disabled,
              'cursor-not-allowed bg-gray-50': disabled
            }
          )}
          onClick={() => setIsOpen((isOpen) => !isOpen)}>
          <span className="block truncate">{children}</span>
        </button>
      )}
    </div>
  )
}

interface DropdownOptionGroupProps {
  title: string
}

function DropdownOptionGroup({
  title,
  children
}: PropsWithChildren<DropdownOptionGroupProps>) {
  return (
    <div>
      <strong className="block px-4 pb-1 pt-3 text-xs font-bold text-gray-600">
        {title}
      </strong>
      <div>
        {Children.map(children, (child) =>
          cloneElement(child as any, { _hasOptionGroupParent: true })
        )}
      </div>
    </div>
  )
}

interface DropdownOptionProps {
  value: string
}

function DropdownOption({
  value,
  children,
  // @ts-ignore used only on internal components
  _hasOptionGroupParent = false
}: PropsWithChildren<DropdownOptionProps>) {
  const { disabled, multiple, value: values } = useContext(DropdownContext)

  return (
    <Listbox.Option
      disabled={disabled}
      value={value}
      className={({ active, selected }) =>
        classNames('block w-full cursor-pointer px-4 py-2 text-left text-sm', {
          'bg-gray-100 text-gray-900': active,
          'bg-gray-50 text-gray-900': !active && selected,
          'text-gray-700': !active && !selected,

          'opacity-50': disabled,
          'cursor-not-allowed': disabled,

          'px-7': _hasOptionGroupParent,
          'px-4': !_hasOptionGroupParent
        })
      }>
      <span className="flex space-x-2">
        {/* We want to display a checkbox but we don't want it to have any events so we hide it behind a transparent element */}
        {multiple && (
          <span className="relative flex">
            <Checkbox
              checked={values.includes(value)}
              onChange={() => {
                /* Noop so React doesn't yell at us for not having a change event */
              }}
            />
            <span className="absolute inset-0" />
          </span>
        )}
        <span>{children}</span>
      </span>
    </Listbox.Option>
  )
}
