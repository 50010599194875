import {
  Button,
  formatCurrency,
  formatDate,
  NotFound,
  Table,
  Wrapper
} from '@cma/common'
import { useBilling, useReceipts } from '@cma/features/settings'
import { CloudCmaHorizontalIcon } from '@cma/icons'
import { PrinterIcon } from '@heroicons/react/outline'
import { Suspense } from 'react'

function BillingReceipt() {
  const { data: billing } = useBilling()
  const { data: receiptsQuery } = useReceipts()

  if (!billing?.user?.isAccountAdmin) {
    return <NotFound />
  }

  return (
    <Wrapper>
      <div className="space-y-8">
        <CloudCmaHorizontalIcon className="w-40 text-cma-500" />
        <div className="flex items-end justify-between space-x-2">
          <div className="space-y-2 overflow-hidden rounded-lg bg-white shadow print:border print:shadow-none">
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Header>Customer</Table.Header>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  <Table.Data>
                    <ul className="space-y-1">
                      {billing.user.name && <li>{billing.user.name}</li>}
                      {billing.user.email && (
                        <li>
                          <a
                            href={`mailto:${billing.user.email}`}
                            className="text-blue-600 hover:no-underline">
                            {billing.user.email}
                          </a>
                        </li>
                      )}
                      {billing.user.companyName && (
                        <li>{billing.user.companyName}</li>
                      )}
                    </ul>
                  </Table.Data>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
          <div className="print:hidden">
            <Button leftIcon={<PrinterIcon />} onClick={() => window.print()}>
              Print
            </Button>
          </div>
        </div>
        <div className="overflow-hidden rounded-lg bg-white shadow print:border print:shadow-none">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Header>Date</Table.Header>
                <Table.Header>Product</Table.Header>
                <Table.Header>Type</Table.Header>
                <Table.Header>Amount</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body data-testid="receipts">
              {!receiptsQuery?.currentUser?.receipts?.length && (
                <Table.Row>
                  <Table.Data colSpan={4}>No receipts found</Table.Data>
                </Table.Row>
              )}
              {receiptsQuery?.currentUser?.receipts?.map((receipt) => (
                <Table.Row key={receipt.createdAt}>
                  <Table.Data data-date={receipt.createdAt}>
                    {formatDate(receipt.createdAt, 'M/d/yyyy h:m:sa')}
                  </Table.Data>
                  <Table.Data>{receipt.product}</Table.Data>
                  <Table.Data>{receipt.type}</Table.Data>
                  <Table.Data>
                    {receipt.type === 'Refund'
                      ? `(${formatCurrency(receipt.amount || 0, '$0.00')})`
                      : formatCurrency(receipt.amount || 0, '$0.00')}
                  </Table.Data>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
    </Wrapper>
  )
}

export default function BillingReceiptsProvider() {
  return (
    <Suspense fallback={<div>Loading Receipts</div>}>
      <BillingReceipt />
    </Suspense>
  )
}
