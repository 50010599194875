import { useCurrentUser } from '@cma/app'
import { Avatar } from '@cma/common'
import { ArrowLeftIcon } from '@cma/icons'
import { HelixIcon } from '@helix/helix-icon'
import { pencil as PencilIcon } from '@helix/helix-icon/outlined'
import { Link, useMatch, useParams } from 'react-router-dom'
import { useBroker } from './useBroker'

export function BrokerHeader() {
  const { brokerId } = useParams()
  const isNew = useMatch('/admin/brokers/new')
  const isEditing = useMatch('/admin/brokers/:brokerId/edit')
  const { data: { broker } = {} } = useBroker({ id: brokerId as string })
  const { data: { currentUser } = {} } = useCurrentUser()

  return (
    <div className="sticky top-0 z-10 flex h-[3.75rem] items-center bg-white p-2 text-xl leading-6 [box-shadow:0_0_1px_rgb(0_0_0_/_20%)] sm:px-8">
      <div className="flex w-2/12">
        <Link
          to={isEditing ? `/admin/brokers/${brokerId}` : `/admin/brokers`}
          className="text-helix-blue-600 inline-flex items-center space-x-2 text-sm">
          <ArrowLeftIcon className="h-5 w-5 flex-shrink-0" />
          <span>{isNew ? 'Broker Accounts' : 'Back'}</span>
        </Link>
      </div>
      <div className="flex w-8/12 items-center justify-center text-gray-900">
        <div className="flex items-center gap-2 truncate">
          {isNew && <div className="truncate">Create New Broker Account</div>}
          {!isNew && !!broker && (
            <>
              <div className="truncate" title={broker?.name}>
                {broker?.name}
              </div>
              <div>
                <Link
                  to={`/admin/brokers/${broker?.id}/edit`}
                  className="flex items-center justify-center rounded-md px-4 py-2 hover:bg-gray-100">
                  <HelixIcon icon={PencilIcon} />
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex w-2/12 items-center justify-end">
        <Avatar
          name={currentUser?.name}
          src={currentUser?.avatar || undefined}
          size="sm"
        />
      </div>
    </div>
  )
}
