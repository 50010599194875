import {
  Address,
  AddressSearch,
  Avatar,
  Button,
  FormField,
  Input,
  Map,
  NotFound,
  Wrapper
} from '@cma/common'
import { EqualHousingIcon, SearchIcon } from '@cma/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { Dispatch, SetStateAction, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import { Illustration } from './Illustration'
import { useEmailHomeWorth } from './useEmailHomeWorth'
import { useHomeWorthUser } from './useHomeWorthUser'

export default function HomeWorth() {
  const { guid } = useParams()
  const { data: { user } = {} } = useHomeWorthUser({ guid })
  const phone = user?.mobilePhone || user?.officePhone
  const [selectedAddress, setSelectedAddress] = useState<Address>()
  const [showForm, setShowForm] = useState(false)
  const [showThanks, setShowThanks] = useState(false)

  if (!guid) {
    return (
      <Wrapper>
        <NotFound />
      </Wrapper>
    )
  }

  return (
    <>
      <Helmet>
        <title>What's your home worth? - Cloud CMA</title>
      </Helmet>
      {!showForm && (
        <>
          <Wrapper>
            <div className="my-6 space-y-16">
              <div className="items-center justify-between space-y-1 sm:flex sm:space-x-4 sm:space-y-0">
                <div className="items-center space-y-4 text-center text-sm text-gray-500 sm:flex sm:flex-row sm:space-x-6 sm:space-y-0 sm:text-left">
                  <Avatar
                    name={user?.name}
                    src={user?.avatar || ''}
                    size="xl"
                  />
                  <div className="space-y-1">
                    <div>{user?.name}</div>
                    <div>{user?.companyName}</div>
                  </div>
                </div>
                <div className="space-y-1 text-center text-sm text-gray-500 sm:text-right">
                  {phone && (
                    <div>
                      <a
                        href={`tel:${phone}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-600 hover:no-underline">
                        {phone}
                      </a>
                    </div>
                  )}
                  {user?.email && (
                    <div>
                      <a
                        href={`mailto:${user?.email}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-600 hover:no-underline">
                        {user?.email}
                      </a>
                    </div>
                  )}
                </div>
              </div>
              {!showThanks && (
                <HomeWorthSearch
                  guid={guid}
                  selectedAddress={selectedAddress}
                  setSelectedAddress={setSelectedAddress}
                  setShowForm={setShowForm}
                />
              )}
              {showThanks && <HomeWorthThanks guid={guid} />}
            </div>
          </Wrapper>
          <div className="mx-auto mt-8 w-full max-w-[1290px]">
            <Illustration />
          </div>
          <Wrapper>
            <div>
              {user?.licenseLabel && user.licenseNumber && (
                <div className="text-center text-sm text-gray-500">
                  {user.licenseLabel} {user.licenseNumber}
                </div>
              )}
              {user?.website && (
                <div className="text-center">
                  <a
                    href={user.website}
                    target="_blank"
                    rel="noreferrer"
                    className="text-sm text-gray-500 underline">
                    {user.website}
                  </a>
                </div>
              )}
              <div className="mx-auto mt-8 w-28 text-gray-500">
                <EqualHousingIcon />
              </div>
            </div>
          </Wrapper>
        </>
      )}
      {showForm && selectedAddress && (
        <HomeWorthForm
          guid={guid}
          address={selectedAddress}
          setShowForm={setShowForm}
          setShowThanks={setShowThanks}
        />
      )}
    </>
  )
}

interface HomeWorthSearchProps {
  guid: string
  selectedAddress?: Address
  setSelectedAddress: Dispatch<SetStateAction<Address | undefined>>
  setShowForm: Dispatch<SetStateAction<boolean>>
}

function HomeWorthSearch({
  guid,
  selectedAddress,
  setSelectedAddress,
  setShowForm
}: HomeWorthSearchProps) {
  return (
    <div className="mx-auto w-full max-w-lg space-y-8">
      <h1 className="text-center text-4xl font-medium text-gray-600">
        What's My Home Worth?
      </h1>
      <form
        className="items-center space-y-2 sm:flex sm:space-x-2 sm:space-y-0"
        onSubmit={(e) => {
          e.preventDefault()
          if (selectedAddress) {
            setShowForm(true)
          }
        }}>
        <div className="grow">
          <AddressSearch
            guid={guid}
            placeholder="Enter your property address"
            size="xl"
            showMlsNum={false}
            onInputChange={() => setSelectedAddress(undefined)}
            onSelect={setSelectedAddress}
          />
        </div>
        <div className="shrink-0">
          <div className="hidden sm:block">
            <Button size="xl" disabled={!selectedAddress}>
              <SearchIcon className="h-6 w-6" />
              <span className="sr-only">Search</span>
            </Button>
          </div>
          <div className="block sm:hidden">
            <Button
              size="xl"
              fullWidth
              leftIcon={<SearchIcon />}
              disabled={!selectedAddress}>
              Search
            </Button>
          </div>
        </div>
      </form>
      <p className="text-center text-sm text-gray-600">
        You'll receive your free comprehensive home report in just a few minutes
      </p>
    </div>
  )
}

const schema = yup.object({
  name: yup.string().required('Your name is required'),
  email: yup.string().email().required('Your email is required'),
  phone: yup.string(),
  sqft: yup.string()
})

interface HomeWorthFormProps {
  guid: string
  address: Address
  setShowForm: Dispatch<SetStateAction<boolean>>
  setShowThanks: Dispatch<SetStateAction<boolean>>
}

function HomeWorthForm({
  guid,
  address,
  setShowForm,
  setShowThanks
}: HomeWorthFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema)
  })
  const {
    mutate: emailHomeWorth,
    isLoading: isEmailing,
    error
  } = useEmailHomeWorth()
  const [searchParams] = useSearchParams()

  return (
    <>
      <div className="h-64 w-full sm:h-[32rem]">
        <Map center={{ lat: address.lat || -1, lng: address.lon || -1 }}>
          <Map.Pin lat={address.lat || -1} lon={address.lon || -1} size="xl" />
        </Map>
      </div>
      <Wrapper>
        <div className="mx-auto w-full max-w-lg space-y-6">
          <h1 className="text-center text-4xl font-medium text-gray-600">
            We Found It
          </h1>
          <h2 className="text-center text-xl text-gray-500">
            {address.formattedAddress}
          </h2>
          <p className="text-center text-sm text-gray-500">
            Please let us know where to send your home valuation:
          </p>
          <form
            className="grid grid-cols-2 gap-4"
            onSubmit={handleSubmit((data) => {
              emailHomeWorth(
                {
                  input: {
                    apiKey: guid,
                    emailTo: data.email,
                    name: data.name,
                    phone: data.phone,
                    sqft: data.sqft,
                    address: address.formattedAddress,
                    lat: String(address.lat) || undefined,
                    lon: String(address.lon) || undefined,
                    callbackUrl: searchParams.get('post_url') || undefined,
                    sourceUrl: searchParams.get('source_url') || undefined
                  }
                },
                {
                  onSuccess() {
                    setShowForm(false)
                    setShowThanks(true)
                  }
                }
              )
            })}>
            <div className="col-span-2 sm:col-span-1">
              <FormField
                label="Full name"
                required
                error={errors.name?.message}>
                <Input
                  type="text"
                  placeholder="John Doe"
                  size="lg"
                  {...register('name')}
                />
              </FormField>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <FormField
                label="Email address"
                required
                error={errors.email?.message}>
                <Input
                  type="email"
                  placeholder="john.doe@example.com"
                  size="lg"
                  {...register('email')}
                />
              </FormField>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <FormField label="Phone" error={errors.phone?.message}>
                <Input
                  type="tel"
                  placeholder="555-555-5555"
                  size="lg"
                  {...register('phone')}
                />
              </FormField>
            </div>
            <div className="col-span-2 sm:col-span-1">
              <FormField
                label="Sq/ft of your home"
                error={errors.sqft?.message}>
                <Input placeholder="1200" size="lg" {...register('sqft')} />
              </FormField>
            </div>
            <div className="col-span-2 text-center">
              <Button size="lg" loading={isEmailing}>
                Email My Report
              </Button>
            </div>
            {error && (
              <p
                className="col-span-2 text-center text-sm text-red-500"
                role="alert"
                aria-live="polite">
                {error.message}
              </p>
            )}
          </form>
        </div>
      </Wrapper>
    </>
  )
}

interface HomeWorthThanksProps {
  guid: string
}

function HomeWorthThanks({ guid }: HomeWorthThanksProps) {
  const { data: { user } = {} } = useHomeWorthUser({ guid })
  const phone = user?.mobilePhone || user?.officePhone

  return (
    <div className="mx-auto w-full max-w-xl space-y-8">
      <h1 className="text-center text-4xl font-medium text-gray-600">
        Your Report Is On Its Way
      </h1>
      <p className="text-center text-sm text-gray-600">
        You should receive a link to your report in a few minutes
      </p>
      <p className="text-center text-sm text-gray-600">
        If you have any questions or want an even more accurate and professional
        valuation of your home, please give me a call at{' '}
        <a
          href={`tel:${phone}`}
          target="_blank"
          rel="noreferrer"
          className="text-blue-600 hover:no-underline">
          {phone}
        </a>{' '}
        or email me at{' '}
        <a
          href={`mailto:${user?.email}`}
          target="_blank"
          rel="noreferrer"
          className="text-blue-600 hover:no-underline">
          {user?.email}
        </a>
        .
      </p>
      <p className="text-center text-sm text-gray-600">
        Thanks for stopping by!
      </p>
    </div>
  )
}
