import { Button, Flash, FormField, Input, Link } from '@cma/common'
import { useResetPassword } from '@cma/features/auth'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

const queryParams = new URLSearchParams(window.location.search)
const token = queryParams.get('reset_password_token')

const schema = yup.object({
  password: yup.string().required('The password is required'),
  passwordConfirmation: yup
    .string()
    .required('The password confirmation is required')
    .oneOf([yup.ref('password'), null], 'Passwords do not match')
})

export default function PasswordReset() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema)
  })
  const {
    mutate: resetPassword,
    isLoading,
    isSuccess,
    error
  } = useResetPassword()

  return (
    <>
      <div className="space-y-4 rounded-lg bg-white px-8 py-6 shadow">
        <h1 className="mb-6 text-center text-2xl font-medium">
          Update Password
        </h1>
        <p className="text-xs">Please enter a new password below</p>
        <form
          className="space-y-4"
          onSubmit={handleSubmit((data) =>
            resetPassword({ ...data, resetPasswordToken: token || '' })
          )}>
          <FormField required label="Password" error={errors.password?.message}>
            <Input type="password" {...register('password')} />
          </FormField>
          <FormField
            required
            label="Confirm Password"
            error={errors.passwordConfirmation?.message}>
            <Input type="password" {...register('passwordConfirmation')} />
          </FormField>
          <div className="flex justify-center">
            <Button loading={isLoading}>Reset Password</Button>
          </div>
          {error && <Flash variant="error">{error?.message}</Flash>}
          {isSuccess && <Flash variant="success">Password Reset!</Flash>}
          {!!error && (
            <div className="text-center text-sm text-red-500">
              {error.message}
            </div>
          )}
        </form>
      </div>
      <div className="flex">
        <Link
          to="/login"
          className="mx-auto text-xs font-medium text-blue-600 hover:no-underline">
          Return to Login
        </Link>
      </div>
    </>
  )
}
