import { AgentIcon, ErrorIcon, InfoIcon, UploadIcon } from '@cma/icons'
import Papa, { ParseResult } from 'papaparse'
import { useCallback, useEffect, useRef, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { Button } from './Button'
import { Spinner } from './Spinner'
import { classNames } from './utils'

type Data = Record<string, string | number | boolean>

export interface CsvData extends ParseResult<Data> {
  fileName: string
}

interface CsvProps {
  limit?: number
  limitLabel?: string
  information?: string[]
  onDrop: (args: CsvData) => void
}

export function CsvDropzone({
  limit,
  limitLabel = 'limit',
  information,
  onDrop
}: CsvProps) {
  const [isParsing, setIsParsing] = useState(false)
  const [parsedFile, setParsedFile] = useState<File>()
  const [error, setError] = useState<string>()
  const onDropRef = useRef<(args: CsvData) => void>(onDrop)

  useEffect(() => {
    onDropRef.current = onDrop
  })

  const handleDrop = useCallback(
    ([csv]: File[], [rejected]: FileRejection[]) => {
      if (csv) {
        setIsParsing(true)
        setParsedFile(csv)
        setError(undefined)
        Papa.parse<Data>(csv, {
          header: true,
          skipEmptyLines: true,
          complete: (data) => {
            setIsParsing(false)

            if (data.errors.length) {
              const [firstError] = data.errors
              const error = firstError.message || 'Invalid csv file'
              setError(error)
              return
            }

            if (limit && data.data.length > limit) {
              const error = `The file is over the ${limit} ${limitLabel}. Check your CSV file and try again.`
              setError(error)
              return
            }

            onDropRef.current({ ...data, fileName: csv.name })
          }
        })
      } else {
        setError('Invalid file type')
        setParsedFile(rejected.file)
      }
    },
    [limit, limitLabel]
  )

  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      disabled: isParsing,
      multiple: false,
      accept: {
        'text/csv': ['.csv']
      },
      onDrop: handleDrop
    })

  return (
    <div
      {...getRootProps()}
      className={classNames(
        'group flex h-full w-full cursor-pointer items-center justify-center rounded border-2 border-dashed border-transparent p-2 sm:p-4',
        {
          'hover:border-blue-600': !isDragActive,
          'border-blue-600': isDragActive && isDragAccept,
          'border-red-500': isDragActive && !isDragAccept
        }
      )}>
      <div>
        <input {...getInputProps()} />
        <div className="mb-9 space-y-4 text-center font-medium text-gray-700">
          <UploadIcon
            className={classNames('mx-auto h-8 w-8', {
              'group-hover:text-blue-600': !isDragActive || isDragAccept,
              'text-red-600': isDragActive && !isDragAccept
            })}
          />
          <p className="text-3xl font-semibold text-black">
            Drag and drop to upload
          </p>
          <Button variant="secondary" size="sm">
            Or browse
          </Button>
        </div>
        <div className="w-full space-y-6 rounded-xl bg-white p-6 text-sm text-gray-900 shadow sm:w-[25rem]">
          {(isParsing || error) && (
            <div className="space-y-8">
              <div className="flex items-center space-x-2 rounded-lg bg-gray-100 p-2 text-xs font-medium text-gray-700">
                {isParsing && (
                  <div className="h-5 w-5">
                    <Spinner />
                  </div>
                )}
                {error && <ErrorIcon className="h-5 w-5 text-red-600" />}
                <div>{parsedFile?.name || 'Unknown file name'}</div>
              </div>
              {error && (
                <p role="alert" aria-live="polite">
                  {error}
                </p>
              )}
            </div>
          )}
          {!isParsing && !error && (
            <>
              <div className="flex items-center space-x-2">
                <AgentIcon className="h-5 w-5 text-gray-600" />
                <div>
                  {limit} {limitLabel}
                </div>
              </div>
              {information?.length && (
                <div className="flex space-x-2">
                  <InfoIcon className="h-5 w-5 text-gray-600" />
                  <div className="space-y-1 text-gray-600">
                    <div className="text-gray-900">Information we need:</div>
                    {information.map((information) => (
                      <div key={information}>{information}</div>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
