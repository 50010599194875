import { useCurrentUser } from '@cma/app'
import {
  Alert,
  Avatar,
  Button,
  Flash,
  FormField,
  generateKey,
  ImageCropperModal,
  Input,
  isCorrectFileSize,
  isSupportedFileType,
  Select,
  SUPPORTED_IMAGE_FORMATS
} from '@cma/common'
import { getAccessTokens } from '@cma/features/auth'
import { SettingsCard, useUpdateProfile } from '@cma/features/settings'
import { UserProfileInput } from '@cma/generated/graphql'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

const schema = yup.object({
  name: yup.string().required('Your name is required'),
  companyname: yup.string(),
  email: yup.string().required('Your email is required'),
  city: yup.string(),
  state: yup.string()
})

export default function Profile() {
  const { data: { currentUser } = {} } = useCurrentUser()
  const {
    mutate: updateProfile,
    error,
    isLoading,
    isSuccess
  } = useUpdateProfile()
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: currentUser?.name ?? '',
      email: currentUser?.email ?? '',
      companyname: currentUser?.companyName ?? '',
      city: currentUser?.city ?? '',
      state: currentUser?.state ?? ''
    }
  })
  const [photoKey, setPhotoKey] = useState(() => generateKey())
  const [logoKey, setLogoKey] = useState(() => generateKey())
  const [showPhotoCropper, setShowPhotoCropper] = useState(false)
  const [showLogoCropper, setShowLogoCropper] = useState(false)
  const [photoPreview, setPhotoPreview] = useState(currentUser?.avatar ?? '')
  const [logoPreview, setLogoPreview] = useState(
    currentUser?.companyLogoUrl ?? ''
  )
  const [photoFile, setPhotoFile] = useState<File | null>()
  const [logoFile, setLogoFile] = useState<File | null>()
  const [photoError, setPhotoError] = useState<string>()
  const [logoError, setLogoError] = useState<string>()
  const [photoCropDimensions, setPhotoCropDimensions] = useState<string>()
  const [logoCropDimensions, setLogoCropDimensions] = useState<string>()
  const inputRef = useRef<HTMLInputElement>(null)
  const accessTokens = getAccessTokens()
  const isImpersonating = !!accessTokens.impersonateToken
  const [showImpersonateWarning, setShowImpersonateWarning] = useState(false)

  const handlePhotoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    const [file] = files || []

    if (file) {
      if (!isSupportedFileType(files)) {
        setPhotoError('Unsupported file type')
        return
      }

      if (!isCorrectFileSize(files)) {
        setPhotoError('Your file must be smaller than 5MB')
        return
      }

      setShowPhotoCropper(true)
      setPhotoFile(file)
    } else {
      setPhotoKey(generateKey())
      setPhotoCropDimensions(undefined)
    }
  }

  const handleLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    const [file] = files || []

    if (file) {
      if (!isCorrectFileSize(files)) {
        setLogoError('Your file must be smaller than 5MB')
        return
      }

      if (!isSupportedFileType(files)) {
        setLogoError('Unsupported file type')
        return
      }

      setShowLogoCropper(true)
      setLogoFile(file)
    } else {
      setLogoKey(generateKey())
      setLogoCropDimensions(undefined)
    }
  }

  const handleUpdate = (data: UserProfileInput) => {
    updateProfile(
      {
        input: {
          ...data,
          photo: photoFile,
          logo: logoFile,
          photoCropDimensions,
          logoCropDimensions
        }
      },
      {
        onSuccess(data) {
          setPhotoPreview(data.updateUserProfile?.user?.avatar || '')
          setLogoPreview(data.updateUserProfile?.user?.companyLogoUrl || '')
        }
      }
    )
  }

  return (
    <>
      <Helmet>
        <title>Profile - Account Settings - Cloud CMA</title>
      </Helmet>
      <SettingsCard>
        <SettingsCard.Group>
          <SettingsCard.Title>Profile</SettingsCard.Title>
          <div className="mb-4 flex flex-col items-center space-y-4">
            <Avatar
              src={photoPreview}
              name={currentUser?.name ?? ''}
              size="4xl"
            />
            <input
              key={photoKey}
              id="photo"
              type="file"
              accept={SUPPORTED_IMAGE_FORMATS.join(',')}
              className="peer h-0 w-0 overflow-hidden"
              onChange={handlePhotoChange}
            />
            <div className="rounded-md outline-2 [outline-color:-webkit-focus-ring-color] peer-focus-visible:outline">
              <Button as="label" htmlFor="photo">
                Change Avatar
              </Button>
            </div>
            {photoError && (
              <div
                className="text-xs text-red-500"
                role="alert"
                aria-live="polite">
                {photoError}
              </div>
            )}
            {!!currentUser?.avatar && (
              <button
                className="text-sm text-blue-600"
                onClick={() => {
                  setPhotoPreview('')
                  setPhotoFile(null)
                  setPhotoCropDimensions(undefined)
                }}>
                Remove my avatar and show initials instead
              </button>
            )}
          </div>
          <form
            className="space-y-4"
            onSubmit={handleSubmit((data) => {
              isImpersonating
                ? setShowImpersonateWarning(true)
                : handleUpdate(data)
            })}>
            <FormField label="Full Name" error={errors.name?.message} required>
              <Input {...register('name')} />
            </FormField>
            <FormField label="Email" error={errors.email?.message} required>
              <Input type="email" {...register('email')} />
            </FormField>
            <FormField label="Company Name" error={errors.companyname?.message}>
              <Input {...register('companyname')} />
            </FormField>
            <FormField
              label="Company Logo"
              secondaryLabel="Max 5MB in size"
              error={logoError}>
              {({ id, ...props }) => (
                <>
                  <input
                    key={logoKey}
                    ref={inputRef}
                    id={id}
                    type="file"
                    accept={SUPPORTED_IMAGE_FORMATS.join(',')}
                    onChange={handleLogoChange}
                    className="sr-only"
                    tabIndex={-1}
                  />
                  <div className="aria-hidden flex space-x-2">
                    <div className="flex-grow">
                      <Input
                        {...props}
                        readOnly
                        key={
                          logoFile === null
                            ? ''
                            : logoFile?.name || currentUser?.companyLogoName
                        }
                        defaultValue={
                          logoFile === null
                            ? ''
                            : logoFile?.name ||
                              currentUser?.companyLogoName ||
                              undefined
                        }
                        onClick={() => inputRef.current?.click()}
                        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                          if (e.key === ' ') {
                            e.preventDefault()
                            inputRef.current?.click()
                          }
                        }}
                      />
                    </div>
                    {!!currentUser?.companyLogoName && logoFile !== null && (
                      <Button
                        type="button"
                        onClick={() => {
                          setLogoPreview('')
                          setLogoFile(null)
                          setLogoCropDimensions(undefined)
                        }}>
                        Remove
                      </Button>
                    )}
                  </div>
                </>
              )}
            </FormField>
            {!!logoPreview && (
              <img
                src={logoPreview}
                alt={currentUser?.companyName ?? 'Company Logo'}
                className="h-12 max-w-full object-cover"
              />
            )}
            <FormField label="City" error={errors.city?.message}>
              <Input {...register('city')} />
            </FormField>
            <FormField label="State" error={errors.state?.message}>
              <Select {...register('state')}>
                <option value="" disabled>
                  Select an Option
                </option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AB">Alberta</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="BC">British Columbia</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MB">Manitoba</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NB">New Brunswick</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NL">Newfoundland and Labrador</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="NS">Nova Scotia</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="ON">Ontario</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="PE">Prince Edward Island</option>
                <option value="PR">Puerto Rico</option>
                <option value="QC">Quebec</option>
                <option value="RI">Rhode Island</option>
                <option value="SK">Saskatchewan</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </Select>
            </FormField>
            <Button fullWidth loading={isLoading}>
              Save Profile
            </Button>
            {error && <Flash variant="error">{error?.message}</Flash>}
            {isSuccess && <Flash variant="success">Profile Updated!</Flash>}
          </form>
        </SettingsCard.Group>
      </SettingsCard>
      <ImageCropperModal
        rounded
        image={photoFile || undefined}
        isOpen={showPhotoCropper}
        onClose={() => {
          setShowPhotoCropper(false)
          setPhotoKey(generateKey())
          setPhotoFile(undefined)
        }}
        onConfirm={({ data, preview }) => {
          setPhotoPreview(preview || '')
          setShowPhotoCropper(false)
          setPhotoCropDimensions(JSON.stringify(data))
        }}
      />
      <ImageCropperModal
        image={logoFile || undefined}
        isOpen={showLogoCropper}
        onClose={() => {
          setShowLogoCropper(false)
          setLogoKey(generateKey())
          setLogoFile(undefined)
        }}
        onConfirm={({ data, preview }) => {
          setLogoPreview(preview || '')
          setShowLogoCropper(false)
          setLogoCropDimensions(JSON.stringify(data))
        }}
      />
      <Alert variant="danger" isOpen={showImpersonateWarning}>
        <Alert.Title>Heads up!</Alert.Title>
        <Alert.Content>
          You're currently logged in as another account (
          <strong className="font-medium text-gray-700">
            {currentUser?.email}
          </strong>
          ). Are you sure you want to perform this update?
        </Alert.Content>
        <Alert.Cancel onClick={() => setShowImpersonateWarning(false)}>
          Cancel
        </Alert.Cancel>
        <Alert.Confirm
          onClick={() => {
            handleUpdate({
              city: getValues('city'),
              companyname: getValues('companyname'),
              email: getValues('email'),
              name: getValues('name'),
              state: getValues('state'),
              photo: photoFile,
              logo: logoFile,
              photoCropDimensions,
              logoCropDimensions
            })
            setShowImpersonateWarning(false)
          }}>
          Confirm Update
        </Alert.Confirm>
      </Alert>
    </>
  )
}
