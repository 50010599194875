import { useCurrentUser } from '@cma/app'
import {
  Alert,
  Button,
  Flash,
  FormField,
  Input,
  Select,
  Spinner,
  statesByName
} from '@cma/common'
import {
  useAddOrChangeAlternateMlsCredential,
  useAlternateMlses,
  useDestroyAlternateMlsCredential,
  useMlses,
  useMlsesByState
} from '@cma/features/settings'
import { useTranslations } from '@cma/features/translations'
import { AlternateMlsPartsFragment } from '@cma/generated/graphql'
import { TrashIcon } from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { Suspense, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

const schema = yup.object({
  id: yup.string(),
  code: yup.string().required('Your MLS Provider is required'),
  name: yup.string().required('Your MLSID is required'),
  password: yup.string()
})

function MlsAlternateCredentials() {
  const { data: { currentUser } = {} } = useCurrentUser()
  const { data: { mlses } = {} } = useMlses()
  const { data: { translations } = {} } = useTranslations()
  const { data: { alternateMlses } = {} } = useAlternateMlses()
  const {
    mutate: addOrChangeMls,
    error,
    isSuccess,
    isLoading: isUpdatingAlternateMlses
  } = useAddOrChangeAlternateMlsCredential()

  const {
    watch,
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      code: '',
      name: '',
      password: ''
    }
  })

  const [showForm, setShowForm] = useState(false)
  const [state, setState] = useState('')

  const [selectedMls, setSelectedMls] =
    useState<AlternateMlsPartsFragment | null>(null)

  const currentMls = mlses?.find((mls) => mls.key === watch('code'))

  useEffect(() => {
    if (showForm) {
      window.scrollTo({ top: window.innerHeight })
    }
  }, [showForm])

  useEffect(() => {
    setState(statesByName[currentMls?.states?.[0] || 'AL']?.name || '')
  }, [currentMls])

  const resetForm = () => {
    setState('')
    setShowForm(false)
    setSelectedMls(null)
    reset({
      id: '',
      code: '',
      name: '',
      password: ''
    })
  }

  const { mlsesByStates, mlsesByState } = useMlsesByState(mlses ?? [], state)

  const mlsLabel = translations?.mls?.label || 'MLS'
  const canEditMls = Boolean(currentUser?.features?.canEditMls)

  const formRef = useRef<HTMLFormElement>(null)

  return (
    <div>
      {(!showForm || (showForm && selectedMls)) && (
        <div className="helix-d-flex helix-flex--column">
          {alternateMlses?.mlses
            ?.filter((altMls) => {
              return selectedMls ? altMls.id === selectedMls.id : true
            })
            .map((altMls) => (
              <AlternateMlsCard
                key={altMls.id}
                mls={altMls}
                canEdit={canEditMls}
                isEditing={altMls.id === selectedMls?.id}
                onEdit={() => {
                  setSelectedMls(altMls)
                  setShowForm(true)
                  reset({
                    id: altMls.id,
                    code: altMls.code || '',
                    name: altMls.name || '',
                    password: altMls.password || ''
                  })
                }}
              />
            ))}
        </div>
      )}
      {canEditMls && (
        <form
          ref={formRef}
          className="space-y-4"
          onSubmit={handleSubmit((data) => {
            if (!data.id) delete data.id
            addOrChangeMls(data, {
              onSuccess() {
                resetForm()
              }
            })
          })}>
          {showForm && (
            <>
              <FormField label="State / Province">
                <Select
                  value={state}
                  onChange={(e) => {
                    setState(e.target.value)
                  }}>
                  <option value="" disabled>
                    Select an Option
                  </option>
                  {Object.keys(mlsesByStates)
                    .sort()
                    .map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                </Select>
              </FormField>
              <FormField
                label={`${mlsLabel} Provider`}
                error={errors.code?.message}
                required>
                <Select {...register('code')}>
                  <option value="" disabled>
                    Select an {mlsLabel}
                  </option>
                  {mlsesByState
                    .sort((a, b) =>
                      (a.title ?? '').localeCompare(b.title ?? '')
                    )
                    .map((mls) => (
                      <option key={mls.key} value={mls.key ?? ''}>
                        {mls.title}
                      </option>
                    ))}
                </Select>
              </FormField>
              <FormField
                label={`${mlsLabel} User ID`}
                error={errors.name?.message}
                required>
                <Input {...register('name')} />
              </FormField>
              <FormField
                label={`${mlsLabel} Password or Pin`}
                error={errors.password?.message}>
                <Input type="password" {...register('password')} />
              </FormField>
            </>
          )}
          <Button
            fullWidth
            loading={isUpdatingAlternateMlses}
            type="button"
            variant={showForm ? 'primary' : 'secondary'}
            onClick={() => {
              if (!showForm) {
                setShowForm(true)
              } else {
                formRef?.current?.dispatchEvent(
                  new Event('submit', { cancelable: true, bubbles: true })
                )
              }
            }}>
            {!showForm
              ? alternateMlses?.mlses?.length
                ? 'Add Another'
                : 'Add Alternate MLS'
              : 'Save MLS Credentials'}
          </Button>
          {showForm && (
            <Button
              fullWidth
              type="button"
              variant="tertiary"
              onClick={resetForm}>
              Cancel
            </Button>
          )}
          {error && <Flash variant="error">{error?.message}</Flash>}
          {isSuccess && (
            <Flash variant="success">{mlsLabel} Credentials Updated!</Flash>
          )}
        </form>
      )}
    </div>
  )
}

interface AlternateMlsCardProps {
  canEdit: boolean
  isEditing: boolean
  onEdit: () => void
  mls: AlternateMlsPartsFragment
}

function AlternateMlsCard({
  mls,
  canEdit,
  isEditing,
  onEdit
}: AlternateMlsCardProps) {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const {
    mutate: destroyMlsCredential,
    isLoading: isDestroying,
    error: destroyError
  } = useDestroyAlternateMlsCredential()

  return (
    <>
      <div className="helix-d-flex helix-justify--between helix-align--center helix-border helix-w-100-percent mb-5 p-4">
        <div>
          {isEditing ? 'Editing' : 'Using'} <strong>{mls.code}</strong> as{' '}
          <code className="helix-bg-gray-200 rounded-sm p-1 text-xs">
            {mls.name}
          </code>
        </div>
        {canEdit && !isEditing && (
          <div className="helix-d-flex space-x-3">
            <Button outline onClick={onEdit}>
              Edit
            </Button>
            <Button
              outline
              variant="danger"
              onClick={() => setShowDeleteModal(true)}>
              <TrashIcon className="h-5 w-5" />
            </Button>
          </div>
        )}
      </div>
      <Alert variant="danger" isOpen={showDeleteModal}>
        <Alert.Title>Delete Alternate MLS</Alert.Title>
        <Alert.Content>
          <div className="space-y-2">
            <div>
              Are you sure you want to delete this MLS (
              <strong className="font-medium text-gray-700">{mls.name}</strong>
              )? This action cannot be undone.
            </div>
            {destroyError && (
              <p className="text-red-500" role="alert" aria-live="polite">
                {destroyError.message}
              </p>
            )}
          </div>
        </Alert.Content>
        <Alert.Cancel onClick={() => setShowDeleteModal(false)}>
          Cancel
        </Alert.Cancel>
        <Alert.Confirm
          loading={isDestroying}
          onClick={() => {
            if (mls.id) destroyMlsCredential({ id: mls.id })
          }}>
          Delete Forever
        </Alert.Confirm>
      </Alert>
    </>
  )
}

export default function MlsAlternateCredentialsProvider() {
  return (
    <Suspense
      fallback={
        <div className="helix-pt-3 flex items-center justify-center space-x-2 text-sm text-gray-500">
          <div className="h-4 w-4">
            <Spinner />
          </div>
          <div>Loading Alternate MLS Credentials</div>
        </div>
      }>
      <MlsAlternateCredentials />
    </Suspense>
  )
}
