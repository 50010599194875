import {
  classNames,
  Confirm,
  formatCurrency,
  formatCurrencyShort,
  formatTime,
  FormField,
  getGoogleAddress,
  getTimeFromDate,
  Input
} from '@cma/common'
import { TourAppointment as ITourAppointment } from '@cma/generated/graphql'
import { ChevronDownIcon, TrashIcon } from '@cma/icons'
import { AnimatePresence, motion } from 'framer-motion'
import { ChangeEvent, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { TourBadge } from './TourBadge'
import { TourButton } from './TourButton'

interface TourAppointmentProps {
  appointment: ITourAppointment
  appointmentDraft?: ITourAppointment
  isOpen?: boolean
  variant?: 'primary' | 'preview'
  onRemove?: () => void
  onClick?: () => void
  onDoneClick?: (args: {
    startTime: string
    endTime: string
    note: string
  }) => void
  onMoreClick?: () => void
  onUpdate?: (args: {
    startTime?: string
    endTime?: string
    note?: string
  }) => void
}

export function TourAppointment({
  isOpen,
  appointment,
  variant = 'primary',
  onRemove,
  onClick,
  onDoneClick,
  onMoreClick,
  onUpdate
}: TourAppointmentProps) {
  const [startTime, setStartTime] = useState(
    getTimeFromDate(appointment.startTime) || ''
  )
  const [endTime, setEndTime] = useState(
    getTimeFromDate(appointment.endTime) || ''
  )
  const [note, setNote] = useState(appointment.note || '')
  const [showDelete, setShowDelete] = useState(false)
  const [firstPhoto] = appointment.listing.photos || []
  const cityStateZip = [
    appointment.listing.city,
    [appointment.listing.state, appointment.listing.zip]
      .filter(Boolean)
      .join(' ')
  ]
    .filter(Boolean)
    .join(', ')
  const isPreview = variant === 'preview'
  const isAdminAndHasTime = !isPreview && startTime && endTime
  const isAdminAndDoesntHaveTime = !isPreview && (!startTime || !endTime)
  const isDraft =
    getTimeFromDate(startTime) !== getTimeFromDate(appointment.startTime) ||
    getTimeFromDate(endTime) !== getTimeFromDate(appointment.endTime) ||
    note !== (appointment.note ?? '')
  const googleAddress = getGoogleAddress({
    address: appointment.listing.address,
    city: appointment.listing.city,
    state: appointment.listing.state,
    zip: appointment.listing.zip
  })

  return (
    <div>
      <button
        className={classNames(
          'relative flex items-center space-x-2 overflow-hidden rounded-full px-2.5 py-0.5 text-xs font-medium shadow-raised',
          {
            'bg-yellow-600 text-white': isDraft || isAdminAndDoesntHaveTime,
            'bg-green-600 text-white':
              !isDraft && (isAdminAndHasTime || (isPreview && isOpen)),
            'bg-gray-200 text-gray-800': isPreview && !isOpen
          }
        )}
        onClick={onClick}>
        <span>
          {isDraft
            ? 'Unsaved Changes'
            : startTime && endTime
            ? `${formatTime(startTime)} - ${formatTime(endTime)}`
            : !isPreview
            ? 'Select Viewing Time'
            : 'Anytime'}
        </span>
        {!isPreview && (
          <ChevronDownIcon
            className={classNames('h-5 w-5 transition-transform', {
              '-rotate-180': isOpen
            })}
          />
        )}
      </button>
      <div
        className={classNames('rounded-md bg-white', {
          'shadow-raised ring-2 ring-yellow-600':
            isDraft || (isAdminAndDoesntHaveTime && isOpen),
          'shadow-raised ring-2 ring-green-600':
            !isDraft && (isAdminAndHasTime || isPreview) && isOpen,
          'shadow-sticky': !isOpen
        })}>
        <div className="relative">
          <div
            role="button"
            tabIndex={-1}
            className="flex items-center space-x-2 p-1"
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                onClick?.()
              }
            }}
            onClick={onClick}>
            <div className="h-[3.375rem] w-[4.5rem] shrink-0 overflow-hidden rounded-md bg-gray-100">
              {firstPhoto && (
                <img
                  src={firstPhoto}
                  alt={appointment.listing.address || ''}
                  className="h-full w-full object-fill text-xs"
                />
              )}
            </div>
            <div className="grow space-y-0.5">
              <div
                className="truncate text-sm font-semibold text-gray-900"
                title={appointment.listing.address || ''}>
                {appointment.listing.address}
              </div>
              <div
                className="truncate text-xs text-gray-600"
                title={cityStateZip}>
                {cityStateZip}
              </div>
            </div>
            <div className="min-w-0 space-y-0.5 pr-2 text-right">
              <div
                className="truncate text-sm font-semibold text-gray-900"
                title={formatCurrency(appointment.listing.price || 0)}>
                {formatCurrencyShort(appointment.listing.price || 0)}
              </div>
              {isPreview ? (
                <div
                  className="leading-1 text-sm text-blue-600 opacity-0"
                  aria-hidden>
                  {/* This is a fake element to correctly position the directions button */}
                  Directions
                </div>
              ) : (
                <TourBadge
                  status={appointment.listing.status || undefined}
                  mappedStatus={appointment.listing.mappedStatus || undefined}
                />
              )}
            </div>
          </div>
          {isPreview && (
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://www.google.com/maps/dir//${googleAddress}`}
              className="leading-1 absolute bottom-2.5 right-3 cursor-pointer text-sm text-blue-600">
              Directions
            </a>
          )}
        </div>
        {!isPreview && (
          <AnimatePresence initial={false}>
            {isOpen && (
              <motion.div
                className="overflow-hidden"
                initial={{ height: 0, opacity: 0.15 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0.15 }}
                transition={{ height: { ease: 'easeInOut', duration: 0.15 } }}>
                <form
                  className="grid grid-cols-2 gap-3 p-3"
                  onSubmit={(e) => {
                    e.preventDefault()
                    onDoneClick?.({ startTime, endTime, note })
                  }}>
                  <FormField label="Start">
                    <Input
                      type="time"
                      value={startTime}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setStartTime(e.target.value)
                        onUpdate?.({ startTime: e.target.value, endTime, note })
                      }}
                    />
                  </FormField>
                  <FormField label="End">
                    <Input
                      type="time"
                      value={endTime}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setEndTime(e.target.value)
                        onUpdate?.({ startTime, endTime: e.target.value, note })
                      }}
                    />
                  </FormField>
                  <div className="col-span-2">
                    <FormField label="Add Remarks">
                      <Input
                        as={TextareaAutosize}
                        value={note}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                          setNote(e.target.value)
                          onUpdate?.({
                            startTime,
                            endTime,
                            note: e.target.value
                          })
                        }}
                      />
                    </FormField>
                  </div>
                  <div className="col-span-2 flex gap-2 lg:grid lg:grid-cols-2">
                    <div className="block lg:hidden">
                      <TourButton
                        type="button"
                        aria-label="Remove"
                        onClick={() => setShowDelete(true)}>
                        <TrashIcon className="h-5 w-5" />
                      </TourButton>
                    </div>
                    <div className="hidden lg:block">
                      <TourButton
                        type="button"
                        leftIcon={<TrashIcon />}
                        onClick={() => setShowDelete(true)}>
                        Remove
                      </TourButton>
                    </div>
                    <div className="block grow lg:hidden">
                      <TourButton type="button" onClick={onMoreClick}>
                        See details
                      </TourButton>
                    </div>
                    <div className="grow">
                      <TourButton>{isDraft ? 'Save' : 'Done'}</TourButton>
                    </div>
                  </div>
                </form>
              </motion.div>
            )}
          </AnimatePresence>
        )}
      </div>
      {!isPreview && (
        <Confirm
          variant="danger"
          isOpen={showDelete}
          title="Remove Property"
          confirmText="Remove It"
          onConfirm={() => onRemove?.()}
          onCancel={() => setShowDelete(false)}
          onClose={() => setShowDelete(false)}>
          Are you sure you want to remove{' '}
          <strong className="font-medium text-gray-700">
            {appointment.listing.address}
          </strong>{' '}
          from this tour?
        </Confirm>
      )}
    </div>
  )
}
