import { NotFound, Wrapper } from '@cma/common'
import {
  CustomizeActivePages,
  CustomizeCover,
  CustomizeFont,
  CustomizeInactivePages,
  CustomizeLanguage,
  CustomizeLayout,
  CustomizeTemplate,
  CustomizeTheme,
  TEMP_CUSTOMIZE_PAGES,
  useCustomizePages
} from '@cma/features/customize'
import {
  ReportHeader,
  ReportPublishButton,
  useReport
} from '@cma/features/report'
import { useMutation } from '@tanstack/react-query'
import { FormEvent } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

function useCma() {
  const { id } = useParams()
  return useReport({ id: id as string })
}

export default function CmaCustomize() {
  const { data: { report } = {} } = useCma()
  const {
    inactivePages,
    activePages,
    addPage,
    removePage,
    sortPages,
    getActivePageKeys
  } = useCustomizePages(TEMP_CUSTOMIZE_PAGES)

  if (!report) return <NotFound />

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    console.log('Submitting these page keys', getActivePageKeys())
    console.log('Navigating to publish')
  }

  return (
    <form onSubmit={handleSubmit}>
      <Wrapper>
        <Helmet>
          <title>Customize Report - CMA Report - Cloud CMA</title>
        </Helmet>
        <ReportHeader
          reportId={report.id}
          title="Customize CMA Report"
          breadcrumbs={[
            { title: 'Criteria', url: `/cmas/${report.id}/edit` },
            { title: 'Listings', url: `/cmas/${report.id}` },
            { title: 'Customize', url: `/cmas/${report.id}/customize` },
            {
              title: 'Publish',
              url: `/cmas/${report.id}/publish`,
              publish: true
            }
          ]}>
          <ReportPublishButton
            reportId={report.id}
            to={`/cmas/${report.id}/publish`}>
            Publish Report
          </ReportPublishButton>
        </ReportHeader>
        <main className="space-y-6">
          <div className="block rounded-lg border md:flex">
            <div className="w-full space-y-8 rounded-tl-lg rounded-tr-lg border-b border-gray-200 bg-[#f0f2f4] p-10 md:w-2/5 md:rounded-tr-none md:rounded-bl-lg md:border-b-0 md:border-r lg:w-1/3">
              <h2 className="mt-1 text-xl font-normal text-gray-700">
                Additional Content
              </h2>
              <div className="space-y-6">
                {Object.values(inactivePages).map((folder) => (
                  <CustomizeInactivePages.Folder
                    key={folder.title}
                    title={folder.title}
                    empty={folder.pages.every((page) =>
                      page.isParent
                        ? page.pages?.every((page) => page.active)
                        : page.active
                    )}>
                    {folder.pages.map((page) => (
                      <CustomizeInactivePages.Page
                        key={page.key}
                        page={page}
                        onAdd={(page) => addPage(page.key)}
                      />
                    ))}
                  </CustomizeInactivePages.Folder>
                ))}
                <button
                  className="text-sm text-blue-600"
                  onClick={() =>
                    console.log('Resetting to default theme pages')
                  }>
                  Reset to default theme pages
                </button>
              </div>
            </div>
            <div className="w-full space-y-8 rounded-bl-lg rounded-br-lg bg-white p-10 md:w-3/5 md:rounded-tr-lg md:rounded-bl-none lg:w-2/3">
              <h2 className="text-2xl font-normal text-gray-700">
                Your CMA Report
              </h2>
              <div className="grid grid-cols-2 gap-5 lg:grid-cols-3">
                <CustomizeTheme onUpdate={() => {}} />
                <CustomizeLayout onUpdate={() => {}} />
                <CustomizeTemplate />
                <CustomizeCover />
                <CmaCustomizeFont />
                <CustomizeLanguage />
              </div>
              <CustomizeActivePages onDragEnd={sortPages}>
                {activePages.map((page, index) => (
                  <CustomizeActivePages.Page
                    key={page.key}
                    index={index}
                    page={page}
                    onDelete={removePage}
                  />
                ))}
              </CustomizeActivePages>
            </div>
          </div>
          <div className="text-center">
            <ReportPublishButton
              size="lg"
              reportId={report.id}
              to={`/cmas/${report.id}/publish`}>
              Publish Report
            </ReportPublishButton>
          </div>
        </main>
      </Wrapper>
    </form>
  )
}

// TODO: Stub for now
function useUpdateCma() {
  return useMutation<any, any>(async () => {})
}

function CmaCustomizeFont() {
  const { data: { report } = {} } = useCma()
  const { mutate: updateCma, isLoading, error } = useUpdateCma()

  if (!report) return null

  return (
    <CustomizeFont
      font={report.font || undefined}
      fonts={report.fonts || undefined}
      loading={isLoading}
      error={error?.message}
      onUpdate={() => updateCma(/*{ id: report.id, font: option.key }*/)}
    />
  )
}
