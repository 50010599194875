/* eslint-disable testing-library/render-result-naming-convention */
import { Wrapper } from '@cma/common'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Document, Page, pdfjs, usePageContext } from 'react-pdf'
import type { RenderParameters } from 'pdfjs-dist/types/src/display/api.js'
import { useSearchParams } from 'react-router-dom'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString()

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/'
}

// http://localhost:3000/themes/modern_penfed_standard/Live_Listing_Presentation_Delaware_Brochure.pdf

export default function Live() {
  const [totalPages, setTotalPages] = useState(0)
  const [searchparams] = useSearchParams()

  const handlePageLoad = () => {
    console.log('page loaded')
  }

  return (
    <Wrapper>
      <Helmet>
        <title>Live CMA - Cloud CMA</title>
        <style>
          {`
          .react-pdf__Page__textContent {
              display: hidden;
              position: absolute;
            }
          `}
        </style>
      </Helmet>

      <Document
        renderMode="custom"
        file={{
          url: searchparams.get('testpdf') || ''
        }}
        loading="loading test pdf"
        options={options}
        onLoad={() => {
          console.log('onload')
        }}
        onLoadSuccess={({ numPages }) => {
          setTotalPages(numPages)
        }}>
        {Array.from(new Array(totalPages), (_el, index) => (
          <Page
            key={index}
            pageNumber={index + 1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            renderMode="custom"
            scale={8}
            onLoadSuccess={(page) => {
              handlePageLoad()
            }}
            className="mb-10 shadow"
            customRenderer={CustomRenderer}
          />
        ))}
      </Document>
    </Wrapper>
  )
}

function CustomRenderer() {
  const pageContext = usePageContext()

  const { _className, page, rotate, scale } = pageContext || {}

  const imageElement = useRef<HTMLImageElement>(null)

  const viewport = useMemo(() => {
    if (page) {
      return page.getViewport({ scale: scale || 1, rotation: rotate })
    }
  }, [page, rotate, scale])

  function drawPageOnImage() {
    if (!page || !viewport) {
      return
    }

    const { current: image } = imageElement

    if (!image) {
      return
    }

    const canvas = document.createElement('canvas')
    canvas.width = viewport?.width || 0
    canvas.height = viewport?.height || 0

    const renderContext: RenderParameters = {
      canvasContext: canvas.getContext('2d', {
        alpha: false
      }) as CanvasRenderingContext2D,
      viewport
    }

    const cancellable = page.render(renderContext)
    const runningTask = cancellable

    cancellable.promise.then(() => {
      image.src = canvas.toDataURL()
    })

    return () => {
      runningTask.cancel()
    }
  }

  useEffect(drawPageOnImage, [imageElement, page, viewport])

  return (
    <img
      ref={imageElement}
      height="auto"
      width="auto"
      alt="test"
      className={`${_className}__image`}
    />
  )
}
