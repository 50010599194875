import { Alert, Button, Dropdown, Modal } from '@cma/common'
import { Color, Select, SelectTheme } from '@cma/generated/graphql'
import { StarIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { CustomizeButton } from './CustomizeButton'
import { CustomizeThemeTryModern } from './CustomizeThemeTryModern'

interface CustomizeLayoutProps {
  theme?: Select
  themes?: SelectTheme[]
  loading?: boolean
  reset?: () => void
  error?: string
  onUpdate: (key: string | undefined) => void
}

function filterThemesByType(themes?: SelectTheme[], type?: string) {
  if (!themes?.length || !type) return

  return themes?.filter(
    (theme) => theme.type.toLowerCase() === type?.toLowerCase()
  )
}

export function CustomizeTheme({
  theme,
  themes,
  loading,
  error,
  reset,
  onUpdate
}: CustomizeLayoutProps) {
  const [showModal, setShowModal] = useState(false)
  const [selectedTheme, setSelectedTheme] = useState<SelectTheme | undefined>(
    themes?.find((_theme) => _theme.key === theme?.key)
  )
  const localStorageValue = JSON.parse(
    window.localStorage.getItem('show-try-modern-theme-modal') || 'null'
  )
  const canShowTryModal = localStorageValue === null ? true : localStorageValue
  const [showTryModernThemeModal, setShowTryModernThemeModal] = useState(
    selectedTheme?.type === 'Franchise' && !!canShowTryModal
  )

  const handleTryModalClose = () => {
    setShowTryModernThemeModal(false)
    window.localStorage.setItem('show-try-modern-theme-modal', 'false')
  }

  const handleTryModernTheme = () => {
    handleTryModalClose()
    setSelectedTheme(
      themes?.find((theme) => theme.key === `modern_${selectedTheme?.key}`)
    )
    onUpdate(`modern_${selectedTheme?.key}`)
  }

  return (
    <>
      <CustomizeButton
        error={error}
        loading={loading}
        icon={<StarIcon />}
        title={`Theme${
          selectedTheme?.type === 'Franchise'
            ? ` ${String.fromCharCode(0x2728)}`
            : ''
        }`}
        subtitle={theme?.value || ''}
        onClick={() => setShowModal(true)}
      />
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} size="xl">
        <Modal.Title>Theme</Modal.Title>
        <div className="mt-4 w-full">
          <Dropdown
            onChange={(v) => {
              setSelectedTheme(themes?.find((theme) => theme.value === v))
            }}
            value={selectedTheme?.value || ''}>
            <Dropdown.Button>
              <Option
                value={selectedTheme?.value || 'Select a Theme'}
                color={
                  selectedTheme?.color || { primary: '000', secondary: '000' }
                }
              />
            </Dropdown.Button>
            <Dropdown.OptionGroup title="Affiliates">
              {filterThemesByType(themes, 'Affiliates')?.map((theme) => (
                <Dropdown.Option key={theme.key} value={theme.value}>
                  <Option value={theme.value} color={theme.color} />
                </Dropdown.Option>
              ))}
            </Dropdown.OptionGroup>
            <Dropdown.OptionGroup title="Branded">
              {filterThemesByType(themes, 'Custom')?.map((theme) => (
                <Dropdown.Option key={theme.key} value={theme.value}>
                  <Option value={theme.value} color={theme.color} />
                </Dropdown.Option>
              ))}
            </Dropdown.OptionGroup>
            <Dropdown.OptionGroup title="Modern">
              {filterThemesByType(themes, 'Modern')?.map((theme) => (
                <Dropdown.Option key={theme.key} value={theme.value}>
                  <Option value={theme.value} color={theme.color} />
                </Dropdown.Option>
              ))}
            </Dropdown.OptionGroup>
            <Dropdown.OptionGroup title="Classic">
              {filterThemesByType(themes, 'Franchise')?.map((theme) => (
                <Dropdown.Option key={theme.key} value={theme.value}>
                  <Option value={theme.value} color={theme.color} />
                </Dropdown.Option>
              ))}
            </Dropdown.OptionGroup>
          </Dropdown>
        </div>
        {!!selectedTheme?.preview?.length && (
          <div className="mt-4 grid grid-cols-1 gap-4 rounded-md border-2 border-gray-100 bg-gray-50 p-4 md:grid-cols-3">
            {selectedTheme?.preview.map((image, index) => (
              <img
                className="m-auto border border-gray-100 shadow-md"
                key={index}
                src={process.env.VITE_APP_CMA_URL + image}
                alt={`${selectedTheme.value} Preview ${index}`}
              />
            ))}
          </div>
        )}
        <div className="flex justify-center pt-4 text-center">
          <Button
            variant="primary"
            onClick={() => {
              setShowModal(false)
              onUpdate(selectedTheme?.key)
            }}>
            Choose Theme
          </Button>
        </div>
      </Modal>
      <CustomizeThemeTryModern
        isOpen={showTryModernThemeModal}
        onClose={() => handleTryModalClose()}
        handleTryModernTheme={() => handleTryModernTheme()}
        theme={selectedTheme}
      />
      <Alert variant="primary" isOpen={!!error}>
        <Alert.Title>Branded Theme</Alert.Title>
        <Alert.Content>
          Your account cannot use the <strong>{selectedTheme?.value}</strong>{' '}
          branded theme. Please choose another theme or{' '}
          <a
            className="font-semibold text-blue-600"
            target="_blank'"
            href="https://cloudagentsuite.com/tools/cma#custom-branded-themes"
            rel="noreferrer">
            click for information about branded themes
          </a>
          .
        </Alert.Content>
        <Alert.Confirm onClick={reset}>Ok</Alert.Confirm>
      </Alert>
    </>
  )
}

interface OptionProps {
  value: string
  color: Color
}

function Option({ value, color }: OptionProps) {
  return (
    <div className="flex items-center space-x-2">
      {!!Object.values(color).length && (
        <span className="flex items-center -space-x-1">
          {Object.values(color).map((color, index) => (
            <span
              key={index}
              style={{ backgroundColor: `#${color}` }}
              className="h-3.5 w-3.5 rounded-full text-sm"
            />
          ))}
        </span>
      )}
      <span>{value}</span>
    </div>
  )
}
