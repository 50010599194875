import { useCurrentUser, useStoreRedirectUrl } from '@cma/app'
import {
  Button,
  copyTextToClipboard,
  Input,
  Spinner,
  useTimedState,
  YoutubeModal
} from '@cma/common'
import {
  Integration,
  IntegrationTransactionDesk,
  IntegrationZipForm,
  SettingsCard
} from '@cma/features/settings'
import { IntegrationTypeEnum } from '@cma/generated/graphql'
import { Suspense, useState } from 'react'
import { Helmet } from 'react-helmet-async'

function Integrations() {
  const { data: { currentUser } = {} } = useCurrentUser()
  const { mutate: storeRedirectUrl } = useStoreRedirectUrl()
  const [showCmaApiHowToVideo, setShowCmaApiHowToVideo] = useState(false)
  const [copied, startCopyTimer] = useTimedState()

  return (
    <SettingsCard>
      <SettingsCard.Group>
        <SettingsCard.Title align="left">Integrations</SettingsCard.Title>
        <div>
          <p className="text-sm text-gray-500">
            Connect Cloud CMA to these services to unlock additional content in
            your reports and improve your workflow.
          </p>
          <div className="space-y-12 py-6">
            {currentUser?.features?.hasTransactions && (
              <div className="space-y-6 border-t border-solid border-gray-200 pt-6">
                <h3 className="mb-6 text-xs font-medium uppercase tracking-wider text-gray-600">
                  Transactions
                </h3>
                <IntegrationZipForm />
                <IntegrationTransactionDesk />
              </div>
            )}
            {(currentUser?.features?.offerRdcToolkit ||
              currentUser?.features?.hasRdcToolkit) && (
              <div className="space-y-6 border-t border-solid border-gray-200 pt-6">
                <h3 className="mb-6 text-xs font-medium uppercase tracking-wider text-gray-600">
                  Promote
                </h3>
                <div className="space-y-2">
                  <div>
                    <h4 className="font-bold text-gray-600">
                      Realtor.com® Listing Toolkit
                    </h4>
                    <p className="text-sm text-gray-600">
                      Impress your clients with the new Realtor.com® Listing
                      Toolkit.
                    </p>
                  </div>
                  {currentUser?.features?.hasRdcToolkit ? (
                    <Button
                      variant="tertiary"
                      as="a"
                      href="https://dashboard.realtor.com/dashboard"
                      rel="noreferrer"
                      target="_blank"
                      fullWidth>
                      Manage your Realtor.com® account
                    </Button>
                  ) : (
                    <Button
                      variant="tertiary"
                      as="a"
                      href="https://succeed.realtor.com/listingtoolkit?utm_source=cloud_cma&utm_medium=website&utm_campaign=ccma_account_settings&utm_term=integrations"
                      fullWidth
                      data-pendo-id="rdc-integrations-learn-more"
                      onClick={() =>
                        storeRedirectUrl({
                          scope: 'rdc',
                          url: window.location.href
                        })
                      }>
                      Learn More
                    </Button>
                  )}
                </div>
              </div>
            )}
            <div className="space-y-6 border-t border-solid border-gray-200 pt-6">
              <h3 className="mb-6 text-xs font-medium uppercase tracking-wider text-gray-600">
                Testimonials
              </h3>
              <div className="space-y-6">
                <Integration
                  service={IntegrationTypeEnum.Ratedagent}
                  title="RatedAgent"
                  label="User ID"
                />
                <Integration
                  service={IntegrationTypeEnum.Reach150}
                  title="Reach150"
                  label="Email"
                />
                <Integration
                  service={IntegrationTypeEnum.Realsatisfied}
                  title="RealSatisfied"
                  label="Vanity Name"
                />
                <Integration
                  service={IntegrationTypeEnum.Testimonialtree}
                  title="Testimonial Tree"
                  label="Email"
                />
                <Integration
                  service={IntegrationTypeEnum.Zillow}
                  title="Zillow"
                  label="Screen Name or Email"
                />
              </div>
            </div>
            <div className="space-y-6 border-t border-solid border-gray-200 pt-6">
              <h3 className="mb-6 text-xs font-medium uppercase tracking-wider text-gray-600">
                Title Insurance
              </h3>
              <Integration
                service={IntegrationTypeEnum.Titlefy}
                title="Titlefy"
                label="Username"
                hasPassword
                addText="Connect"
                removeText="Disconnect"
              />
            </div>
            {currentUser?.features?.hasApiButton && (
              <div className="space-y-1 border-t border-solid border-gray-200 pt-6">
                <h3 className="mb-6 text-xs font-medium uppercase tracking-wider text-gray-600">
                  Cloud CMA
                </h3>
                <h4 className="font-bold text-gray-600">Your API Key</h4>
                <p className="mb-1 text-sm text-gray-500">
                  Use this key in apps like Zillow Tech Connect.{' '}
                  <button
                    className="text-blue-600"
                    onClick={() => setShowCmaApiHowToVideo(true)}>
                    See how
                  </button>
                  .
                </p>
                <div className="flex items-center space-x-2">
                  <div className="flex-grow">
                    <Input readOnly defaultValue={currentUser.guid || ''} />
                  </div>
                  <Button
                    onClick={() => {
                      copyTextToClipboard(currentUser.guid || '')
                      startCopyTimer()
                    }}>
                    {copied ? 'Copied!' : 'Copy'}
                  </Button>
                </div>
                <YoutubeModal
                  videoId="hiu9dqejtPQ"
                  isOpen={showCmaApiHowToVideo}
                  onClose={() => setShowCmaApiHowToVideo(false)}
                />
              </div>
            )}
          </div>
        </div>
      </SettingsCard.Group>
    </SettingsCard>
  )
}

export default function IntegrationsProvider() {
  return (
    <>
      <Helmet>
        <title>Integrations - Account Settings - Cloud CMA</title>
      </Helmet>
      <Suspense
        fallback={
          <SettingsCard>
            <SettingsCard.Group>
              <SettingsCard.Title align="left">Integrations</SettingsCard.Title>
              <div className="flex items-center justify-center space-x-2 text-sm text-gray-500">
                <div className="h-4 w-4">
                  <Spinner />
                </div>
                <div>Loading Integrations</div>
              </div>
            </SettingsCard.Group>
          </SettingsCard>
        }>
        <Integrations />
      </Suspense>
    </>
  )
}
