import { useCurrentUser } from '@cma/app'
import {
  Avatar,
  Button,
  Checkbox,
  classNames,
  FormField,
  Input,
  Modal
} from '@cma/common'
import { CloudCmaHorizontalIcon } from '@cma/icons'
import { Tab } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import pluralize from 'pluralize'
import { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { ReportCardPreview } from './ReportCardPreview'
import { useEmailReport } from './useEmailReport'

const schema = yup.object({
  to: yup
    .string()
    .required('An email address is required')
    .email('Invalid email address'),
  subject: yup.string().required('The subject is required'),
  intro: yup.string().required('The intro is required'),
  pdfReport: yup.boolean(),
  slideshowReport: yup.boolean(),
  liveReport: yup.boolean()
})

interface EmailReport {
  __typename: string
  id: string
  status?: string | null
  thumbnail?: string | null
  addressParts?: string[] | null
  title?: string | null
  listingCount?: number | null
  pdfPermalink?: string | null
  guid?: string | null
}

interface EmailReportProps {
  report: EmailReport
  isOpen: boolean
  onClose: () => void
}

export function EmailReport({ report, isOpen, onClose }: EmailReportProps) {
  const {
    subject,
    intro,
    includePdfReport,
    includeSlideshowReport,
    includeLiveReport,
    isPdfReportChecked,
    isSlideshowReportChecked,
    isLiveReportChecked
  } = getDetails(report)
  const { data: { currentUser } = {} } = useCurrentUser()
  const {
    mutate: emailReport,
    isSuccess: reportSent,
    isLoading: isSending,
    error: emailReportError,
    reset: resetEmailReport
  } = useEmailReport()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset: resetForm
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      to: '',
      subject: subject || '',
      intro: intro || '',
      pdfReport: isPdfReportChecked,
      slideshowReport: isSlideshowReportChecked,
      liveReport: isLiveReportChecked
    }
  })
  const address = watch('to')
  const mailTo = useGetMailToLink({
    report,
    to: address,
    subject: watch('subject'),
    isPdfReportChecked: watch('pdfReport') ?? isPdfReportChecked,
    isSlideshowReportChecked:
      watch('slideshowReport') ?? isSlideshowReportChecked,
    isLiveReportChecked: watch('liveReport') ?? isLiveReportChecked
  })
  const hasErrors = !!Object.values(errors).length

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onAfterClose={() => {
        resetForm()
        resetEmailReport()
      }}>
      <div className="mb-4">
        <Modal.Title>Email Report</Modal.Title>
      </div>
      {!reportSent && (
        <form
          onSubmit={handleSubmit((data) =>
            emailReport({
              id: report.id,
              input: {
                showLiveUrl: !!data.liveReport,
                showPdfUrl: !!data.pdfReport,
                showSlideshowUrl: !!data.slideshowReport,
                to: data.to,
                subject: data.subject,
                msg: data.intro
              }
            })
          )}>
          <Tab.Group>
            <Tab.List className="-mx-4 space-x-6 border-b px-4 sm:-mx-6 sm:px-6">
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={classNames(
                      'relative border-b-2 pb-2 text-sm font-medium',
                      {
                        'border-transparent': !selected,
                        'border-blue-600': selected && !hasErrors,
                        'border-red-500': selected && hasErrors,
                        'text-gray-700': !hasErrors,
                        'text-red-500': hasErrors
                      }
                    )}>
                    Customize
                    {hasErrors && (
                      <span className="absolute top-0 -right-1.5 flex h-1.5 w-1.5">
                        <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-300 opacity-75"></span>
                        <span className="relative inline-flex h-1.5 w-1.5 rounded-full bg-red-400"></span>
                      </span>
                    )}
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={classNames(
                      'border-b-2 pb-2 text-sm font-medium text-gray-700',
                      {
                        'border-transparent': !selected,
                        'border-blue-600': selected
                      }
                    )}>
                    Preview
                  </button>
                )}
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <div className="space-y-3 pt-4 pb-4 sm:pb-6">
                  <FormField label="To" error={errors.to?.message} required>
                    <Input
                      {...register('to')}
                      placeholder="Enter email address"
                    />
                  </FormField>
                  <FormField
                    label="Subject"
                    error={errors.subject?.message}
                    required>
                    <Input {...register('subject')} />
                  </FormField>
                  <FormField
                    label="Intro"
                    error={errors.intro?.message}
                    required>
                    <Input {...register('intro')} />
                  </FormField>
                  {(includePdfReport ||
                    includeSlideshowReport ||
                    includeLiveReport) && (
                    <div className="space-y-2">
                      <strong className="text-sm font-medium text-gray-700">
                        Include
                      </strong>
                      <div className="-mx-4 h-[7.875rem] space-y-2 bg-gray-50 px-4 py-4 sm:-mx-6 sm:px-6">
                        {includePdfReport && (
                          <div>
                            <Checkbox
                              size="lg"
                              defaultChecked={isPdfReportChecked}
                              {...register('pdfReport')}>
                              PDF Report
                            </Checkbox>
                          </div>
                        )}
                        {includeSlideshowReport && (
                          <div>
                            <Checkbox
                              size="lg"
                              defaultChecked={isSlideshowReportChecked}
                              {...register('slideshowReport')}>
                              Slideshow Report
                            </Checkbox>
                          </div>
                        )}
                        {includeLiveReport && (
                          <div>
                            <Checkbox
                              size="lg"
                              defaultChecked={isLiveReportChecked}
                              {...register('liveReport')}>
                              Live Report
                            </Checkbox>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Tab.Panel>
              <Tab.Panel className="-mx-4 mb-4 h-96 space-y-6 overflow-y-auto bg-gray-50 px-4 py-4 sm:-mx-6 sm:mb-6 sm:px-6 sm:py-7">
                <div className="text-center text-sm text-gray-700">
                  {watch('intro') || (
                    <div className="italic text-red-500">No Intro</div>
                  )}
                </div>
                <div className="flex justify-center">
                  <ReportCardPreview
                    report={report}
                    showPdfReport={watch('pdfReport') ?? isPdfReportChecked}
                    showSlideshowReport={
                      watch('slideshowReport') ?? isSlideshowReportChecked
                    }
                    showLiveReport={watch('liveReport') ?? isLiveReportChecked}
                  />
                </div>
                <div className="text-center text-sm">
                  <div className="mb-2">
                    <Avatar
                      src={currentUser?.avatar || undefined}
                      name={currentUser?.name}
                    />
                  </div>
                  <strong className="font-medium text-gray-700">
                    {currentUser?.name}
                  </strong>
                  <div className="text-gray-500">{currentUser?.email}</div>
                </div>
                <div className="space-y-2 text-center text-xs text-gray-400">
                  <div>Powered By</div>
                  <CloudCmaHorizontalIcon className="mx-auto h-4 w-24" />
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
          <div className="flex items-center justify-between space-x-2">
            <div className="text-sm font-medium text-gray-700">
              Send from your{' '}
              <a
                href={mailTo}
                target="_blank"
                rel="noreferrer"
                className="text-blue-600">
                email app
              </a>
            </div>
            <div>
              <Button loading={isSending}>Send</Button>
            </div>
          </div>
        </form>
      )}
      {reportSent && (
        <div className="flex h-[30rem] flex-col items-center justify-center space-y-4">
          <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="h-14 w-14 fill-blue-600">
            <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
          <div className="text-gray-600">Report Sent!</div>
          <a
            href={`mailto:${address}`}
            target="_blank"
            rel="noreferrer"
            className="text-blue-600">
            {address}
          </a>
        </div>
      )}
      {emailReportError && (
        <p
          role="alert"
          aria-live="polite"
          className="mt-2 text-sm text-red-600">
          {emailReportError.message}
        </p>
      )}
    </Modal>
  )
}

interface UseGetMailToLinkArgs {
  report: EmailReport
  to: string
  subject: string
  isPdfReportChecked: boolean
  isSlideshowReportChecked: boolean
  isLiveReportChecked: boolean
}

function useGetMailToLink({
  report,
  to,
  subject,
  isPdfReportChecked,
  isSlideshowReportChecked,
  isLiveReportChecked
}: UseGetMailToLinkArgs) {
  const { data: { currentUser } = {} } = useCurrentUser()
  const { friendlyLabel } = getDetails(report)

  let message = ''
  if (isPdfReportChecked)
    message += `Here is your customized ${friendlyLabel}:\n${report.pdfPermalink}\n\n`
  if (isSlideshowReportChecked)
    message += `Here is your customized ${friendlyLabel} Slideshow:\n${window.location.origin}/present/${report.guid}\n\n`
  if (isLiveReportChecked)
    message += `Here is your customized live ${friendlyLabel}:\n${window.location.origin}/live/${report.guid}\n\n`
  message += `Regards,\n${currentUser?.name}\n${currentUser?.email}`

  return `mailto:${to}?subject=${subject}&body=${message
    .replace(/(?:\r\n|\r|\n)/g, '%0D%0A')
    .trim()}`
}

function getDetails(report: EmailReport) {
  switch (report.__typename) {
    case 'CmaReport':
      return {
        friendlyLabel: 'CMA Report',
        subject: `CMA for ${report.addressParts?.[0] || report.title}`,
        intro: 'Your Comparative Market Analysis report is ready:',
        includePdfReport: true,
        includeSlideshowReport: true,
        includeLiveReport: true,
        isPdfReportChecked: true,
        isSlideshowReportChecked: false,
        isLiveReportChecked: true
      }
    case 'DocumentReport':
      return {
        friendlyLabel: 'Document',
        subject: `Document for ${report.addressParts?.[0] || report.title}`,
        intro: 'Your Document is ready:',
        includePdfReport: true,
        includeSlideshowReport: false,
        includeLiveReport: false,
        isPdfReportChecked: true,
        isSlideshowReportChecked: false,
        isLiveReportChecked: false
      }
    case 'FlyerReport':
      return {
        friendlyLabel: 'Flyer',
        subject: `Flyer for ${report.addressParts?.[0] || report.title}`,
        intro: 'Your Flyer is ready:',
        includePdfReport: true,
        includeSlideshowReport: false,
        includeLiveReport: false,
        isPdfReportChecked: true,
        isSlideshowReportChecked: false,
        isLiveReportChecked: false
      }
    case 'PropertyReport':
      return {
        friendlyLabel: 'Property Report',
        subject: `Property Report for ${
          report.addressParts?.[0] || report.title
        }`,
        intro: 'Your Property report is ready:',
        includePdfReport: true,
        includeSlideshowReport: false,
        includeLiveReport: false,
        isPdfReportChecked: true,
        isSlideshowReportChecked: false,
        isLiveReportChecked: false
      }
    case 'TourReport':
      return {
        friendlyLabel: 'Buyer Tour',
        subject: `Buyer Tour for ${pluralize(
          'Properties',
          report.listingCount ?? 0,
          true
        )}`,
        intro: 'Your Buyer Tour is ready:',
        includePdfReport: true,
        includeSlideshowReport: true,
        includeLiveReport: false,
        isPdfReportChecked: true,
        isSlideshowReportChecked: false,
        isLiveReportChecked: false
      }
    default:
      return {
        friendlyLabel: 'Report',
        subject: `Report for ${report.addressParts?.[0] || report.title}`,
        intro: 'Your report is ready:',
        includePdfReport: true,
        includeSlideshowReport: false,
        includeLiveReport: false,
        isPdfReportChecked: false,
        isSlideshowReportChecked: false,
        isLiveReportChecked: false
      }
  }
}
