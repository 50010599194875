import { format } from 'date-fns'
import isUrl from 'is-url'

const dateISORegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/i

interface ListingFeaturesProps {
  features: string | Record<string, any>
}

export function ListingFeatures({ features: _features }: ListingFeaturesProps) {
  const features =
    typeof _features === 'string' ? JSON.parse(_features || '{}') : _features

  return (
    <div className="columns-2 space-y-5">
      {(Object.entries(features) as unknown as [string, any]).map(
        ([key, value]) => (
          <div key={key} className="text-xs font-light text-gray-900">
            <strong className="font-semibold">{key}</strong>:{' '}
            {isUrl(value) ? (
              <a
                href={value}
                target="_blank"
                rel="noreferrer"
                className="text-blue-600">
                View
              </a>
            ) : dateISORegex.test(value) ? (
              format(new Date(value), 'MM/dd/yyyy')
            ) : (
              value
            )}
          </div>
        )
      )}
    </div>
  )
}
