import { useCurrentUser } from '@cma/app'
import { activeClassName, Wrapper } from '@cma/common'
import { getAccessTokens } from '@cma/features/auth'
import { Suspense } from 'react'
import { NavLink, Outlet } from 'react-router-dom'

export default function SettingsLayout() {
  const { data } = useCurrentUser()
  const accessTokens = getAccessTokens()
  const isImpersonating = !!accessTokens.impersonateToken

  return (
    <Wrapper>
      <h1 className="text-4xl font-medium">Account Settings</h1>
      <div className="flex flex-col items-start space-y-6 sm:flex-row sm:space-x-12 sm:space-y-0">
        <aside className="static w-full sm:sticky sm:top-20 sm:mt-6 sm:w-3/12">
          <nav className="flex sm:justify-end">
            <ul className="space-y-4 text-sm">
              <li>
                <NavLink
                  to="/settings"
                  className={activeClassName(
                    'text-blue-600',
                    'text-gray-500',
                    'hover:no-underline'
                  )}
                  end>
                  Profile
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/settings/contact-info"
                  className={activeClassName(
                    'text-blue-600',
                    'text-gray-500',
                    'hover:no-underline'
                  )}>
                  Contact Info
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/settings/password"
                  className={activeClassName(
                    'text-blue-600',
                    'text-gray-500',
                    'hover:no-underline'
                  )}>
                  Password
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/settings/mls-credentials"
                  className={activeClassName(
                    'text-blue-600',
                    'text-gray-500',
                    'hover:no-underline'
                  )}>
                  MLS Credentials
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/settings/custom-pages"
                  className={activeClassName(
                    'text-blue-600',
                    'text-gray-500',
                    'hover:no-underline'
                  )}>
                  Custom Pages
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/settings/integrations"
                  className={activeClassName(
                    'text-blue-600',
                    'text-gray-500',
                    'hover:no-underline'
                  )}>
                  Integrations
                </NavLink>
              </li>
              <li>
                {!isImpersonating &&
                data?.currentUser?.lwaId &&
                !(
                  data?.currentUser?.isAccountAdmin &&
                  data.currentUser?.account?.isSmallBroker
                ) ? (
                  <a
                    href={`${process.env.VITE_APP_LW_AGENT_URL}/billing?jwt=${data?.currentUser?.lwaJwt}`}
                    className="text-gray-500 hover:no-underline">
                    Billing
                  </a>
                ) : (
                  <NavLink
                    to="/settings/billing"
                    className={activeClassName(
                      'text-blue-600',
                      'text-gray-500',
                      'hover:no-underline'
                    )}>
                    Billing
                  </NavLink>
                )}
              </li>
            </ul>
          </nav>
        </aside>
        <main className="w-full sm:w-7/12">
          <Suspense>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </Wrapper>
  )
}
